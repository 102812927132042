import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "custom-hook-form";

import { theme } from "twin.macro";
import Input from "@molecules/Input";
import Button from "@atoms/Button";
import Paragraph from "@atoms/Paragraph";
import useGoogleReCaptchaV3 from "@/hooks/useGoogleReCaptchaV3";
import { SITEKEYCAPTCHA } from "@/recourses/constants";
import { sendWaitingList } from "@/services/common/waitingList";
import CheckLocation from "@iconsV2/Illustration/checkLocation.svg?react";
import { generateLead } from "@/utils/analyticsV2";
import { validateErrorsAPI } from "@/utils/validateAPIErrors";
import { StyledCityFeedbackForm, StyledCityFeedbackSuccess } from "./styles";

interface ICityFeedbackForm {
  email: string;
  cityFeedbackForm: string;
}

interface WaitingListData {
  city?: string | null;
  email?: string | null;
  recaptcha_token: string;
}

interface CityFeedbackFormProps {
  cityDefault?: string;
  cityFormDefault?: string;
  successProps?: {
    showSuccessDescription?: boolean;
    showSuccessButton?: boolean;
  };
  onAccept?: () => void;
  withShadow?: boolean;
}

const CityFeedbackForm = ({
  cityDefault,
  cityFormDefault,
  successProps = {
    showSuccessButton: true,
    showSuccessDescription: true,
  },
  onAccept,
  withShadow = true,
}: CityFeedbackFormProps) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ICityFeedbackForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const mutation = useMutation({
    mutationFn: async (data: WaitingListData) => await sendWaitingList(data),
    onSuccess: () => {
      reset();
    },
    onError: (errors: any) => {
      if (errors?.data) {
        const error = validateErrorsAPI(errors?.data as Record<string, string>);
        let keyError: "root" | "cityFeedbackForm" | "email" = "root";

        if (error.key === "city") {
          keyError = "cityFeedbackForm";
        }
        if (error.key === "email") {
          keyError = "email";
        }

        setError(keyError, {
          message: error.message,
        });
      } else {
        setError("root", {
          message: "Ha ocurrido un error, por favor intenta de nuevo.",
        });
      }
    },
  });

  const { executeReCaptcha } = useGoogleReCaptchaV3({
    siteKey: SITEKEYCAPTCHA,
    shouldLoad: !mutation.isSuccess,
  });

  const onSubmit = handleSubmit(async (formData) => {
    const { cityFeedbackForm, email } = formData;
    if (!cityFeedbackForm || !email || mutation.isPending || isSubmitting) return;

    const recaptchaToken = await executeReCaptcha("submit");

    if (recaptchaToken) {
      const data: WaitingListData = {
        city: cityFeedbackForm,
        email,
        recaptcha_token: recaptchaToken,
      };

      mutation.mutate(data);
    }
  });

  useEffect(() => {
    generateLead.generateLeadForm({
      formFields: ["city", "email"],
      formLength: 2,
      formName: "newletter-city-feeback",
    });
  }, []);

  useEffect(() => {
    if (cityFormDefault !== undefined) {
      setValue("cityFeedbackForm", cityFormDefault);
    }
  }, [cityFormDefault]);

  return (
    <>
      {mutation.isSuccess ? (
        <StyledCityFeedbackSuccess $withShadow={withShadow} $defaultPadding={Boolean(!onAccept)}>
          <CheckLocation />

          <div tw="flex flex-col text-center items-center gap-s">
            <Paragraph sizeVariant="lg">¡Gracias por tu información!</Paragraph>

            {successProps.showSuccessDescription && onAccept && (
              <Paragraph sizeVariant="md">
                Te mostramos los productos disponibles para {cityDefault ?? "Barranquilla"} y te contactaremos
                cuando expandamos a nuevas ciudades.
              </Paragraph>
            )}
          </div>

          {successProps.showSuccessButton && onAccept && (
            <div>
              <Button
                color="secondary"
                sizeVariant="lg"
                variant="filled"
                onClick={() => {
                  onAccept?.();
                }}
              >
                Entendido
              </Button>
            </div>
          )}
        </StyledCityFeedbackSuccess>
      ) : (
        <StyledCityFeedbackForm onSubmit={onSubmit}>
          <Paragraph sizeVariant="sm">
            ¿Estás fuera de Barranquilla, Bogotá y Medellín? Únete a nuestra lista y te avisamos cuando
            lleguemos a tu ciudad
          </Paragraph>
          <Controller
            name="cityFeedbackForm"
            control={control}
            rules={{
              required: "Debes ingresar una ciudad",
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                type="text"
                label="¿En qué ciudad te encuentras?"
                placeholder="Ingresa tu ciudad de domicilio"
                size="medium"
                errors={!!error?.message}
                textHelp={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{
              required: "Debes ingresar un email",
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                type="email"
                label="Ingresa tu correo y te avisamos cuando lleguemos a tu ciudad"
                placeholder="Ingresa tu correo"
                size="medium"
                autoComplete="email"
                errors={!!error?.message}
                textHelp={error?.message}
                {...field}
              />
            )}
          />

          <div>
            <Button
              color="secondary"
              sizeVariant="lg"
              variant="outlined"
              type="submit"
              disabled={mutation.isPending}
            >
              Enviar
            </Button>
          </div>

          {errors?.root?.message && (
            <Paragraph sizeVariant="sm" color={theme`colors.error.40`}>
              {errors.root?.message}
            </Paragraph>
          )}
        </StyledCityFeedbackForm>
      )}
    </>
  );
};

export default CityFeedbackForm;
