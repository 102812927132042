import tw, { styled } from "twin.macro";
import Image from "@components/Atoms/Image";

export const StyledImage = styled(Image)`
  ${tw`w-[1.875rem] h-[1.875rem] medium:(w-[2.5rem] h-[2.5rem]) large:(w-[3.5rem] h-[3.5rem]) aspect-square!`}

  span {
    ${tw`w-full h-full`}
  }
`;

export const StyledHubCardRoot = styled.div`
  ${tw`w-[6rem] medium:w-[8.063rem] large:w-[9rem]  justify-center mr-1.5`}
`;
