import { Navigate, useParams, type RouteObject } from "react-router-dom";

// Redirects
const RedirectHub = () => {
  const { hubsSlug } = useParams();
  return <Navigate to={`/h/${hubsSlug}`} replace />;
};

const RedirectCategory = () => {
  const { categorySlug } = useParams();
  return <Navigate to={`/c/${categorySlug}`} replace />;
};

const RedirectBrand = () => {
  const { brandSlug } = useParams();
  return <Navigate to={`/b/${brandSlug}`} replace />;
};

const RedirectProduct = () => {
  const { productSlug } = useParams();
  return <Navigate to={`/p/${productSlug}`} replace />;
};

const RedirectHome = () => {
  return <Navigate to={`/`} replace />;
};

const publicRoutes: RouteObject[] = [
  {
    index: true,
    lazy: async () => {
      const { default: Home } = await import("@pages/Home");
      return {
        element: <Home />,
      };
    },
  },
  {
    path: "search",
    lazy: async () => {
      const { loader, default: Search } = await import("@pages/Search");
      return {
        loader,
        element: <Search />,
      };
    },
  },
  {
    path: "/p/:productSlug",
    lazy: async () => {
      const { default: Product } = await import("@/Pages/Product");
      return {
        element: <Product />,
      };
    },
  },
  {
    path: "c/:categorySlug",
    lazy: async () => {
      const { loader, default: Category } = await import("@/Pages/Category");
      return {
        loader,
        element: <Category />,
      };
    },
  },
  {
    path: "h/:hubSlug",
    lazy: async () => {
      const { loader, default: Hub } = await import("@/Pages/Hub");
      return {
        loader,
        element: <Hub />,
      };
    },
  },
  {
    path: "b/:brandSlug",
    lazy: async () => {
      const { loader, default: Brand } = await import("@/Pages/Brand");
      return {
        loader,
        element: <Brand />,
      };
    },
  },
  {
    path: "stores",
    lazy: async () => {
      const { default: Stores } = await import("@/Pages/Stores");
      return {
        element: <Stores />,
      };
    },
  },
  {
    path: "/tiendas",
    element: <Navigate to="/stores" replace />,
  },
  // Redirects from old pages
  {
    path: "stores/:type/",
    Component: RedirectHome,
  },
  {
    path: "gift-cards/buy",
    element: <Navigate to="/gift-cards/redeem" replace />,
  },
  {
    path: "stores/:type/c/:categorySlug",
    Component: RedirectCategory,
  },
  {
    path: "stores/:type/c/:categorySlug/:categorySlug",
    Component: RedirectCategory,
  },
  {
    path: "stores/:type/b/:brandSlug",
    Component: RedirectBrand,
  },
  {
    path: "stores/:type/h/:hubsSlug",
    Component: RedirectHub,
  },
  {
    path: "stores/:type/p/:productSlug",
    Component: RedirectProduct,
  },
  {
    path: "stores/restaurant",
    element: <Navigate to="/stores/market/c/restaurante" replace />,
  },
  {
    path: "terms",
    lazy: async () => {
      const { default: TermsAndConditions } = await import("@/Pages/TermsAndConditions");
      return {
        element: <TermsAndConditions />,
      };
    },
  },
  {
    path: "policy",
    lazy: async () => {
      const { default: Policy } = await import("@/Pages/Policy");
      return {
        element: <Policy />,
      };
    },
  },
  // {
  //   path: "flash",
  //   lazy: async () => {
  //     const { default: Flash } = await import("@/Pages/Flash");
  //     return {
  //       element: <Flash />,
  //     };
  //   },
  // },
];

// TODO: Delete component after migration
const oldPublicRoutes = [
  // {
  //   index: true,
  //   element: <Navigate replace to="/stores/market" />,
  // },
  // {
  //   path: "stores/:type/categories",
  //   lazy: async () => {
  //     const { Categories } = await import("@/screens/Categories");
  //     return {
  //       element: <Categories />,
  //     };
  //   },
  // },
  {
    path: "gift-cards/redeem",
    lazy: async () => {
      const { RedeemGiftCard } = await import("@/screens/GiftCard");
      return {
        element: <RedeemGiftCard />,
      };
    },
  },
  {
    path: "promo/:id",
    lazy: async () => {
      const { Promo } = await import("@/screens/Promo");
      return {
        element: <Promo />,
      };
    },
  },
  // {
  //   path: "orders/:orderId/rate",
  //   lazy: async () => {
  //     const { RateOrder } = await import("@/screens/RateOrder");
  //     return {
  //       element: <RateOrder />,
  //     };
  //   },
  // },
];

/*
// import { PlusHome, PlusTermsAndCondition, PlusBuy } from "../screens/Plus";

<Route path="/plus">
      <PlusHome />
</Route>
<Route path="/plus/terms">
    <PlusTermsAndCondition />
</Route>
<Route path="/plus/buy">
    <PlusBuy />
</Route> */

export { oldPublicRoutes, publicRoutes };
