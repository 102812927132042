import {
  forwardRef,
  type CSSProperties,
  type ButtonHTMLAttributes,
  type FC,
  type PropsWithRef,
  type ReactNode,
  useMemo,
} from "react";
import type { RuleSet } from "styled-components";
import type { TwStyle } from "twin.macro";

import type { ITypographyCommon, TColor } from "@/types/common";

import { StyledEndIcon, StyledStartIcon, StyledWrapperButton, StyledWrapperChildren } from "./styles";
import Loader, { type LoaderProps } from "../Loader";

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement>, ITypographyCommon {
  variant?: "filled" | "outlined" | "text";
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  justify?: CSSProperties["justifyContent"];
  color?: TColor | "error";
  fontWeight?: CSSProperties["fontWeight"];
  loading?: boolean;
  loaderVariant?: LoaderProps["variant"];
  loaderSize?: CSSProperties["width"];
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  [key: string]: any;
}

const Button: FC<PropsWithRef<IButton>> = forwardRef(
  (
    {
      children,
      size,
      sizeVariant,
      loaderVariant = "circleBold",
      startIcon,
      endIcon,
      color = "primary",
      variant = "filled",
      fullWidth = false,
      justify = "center",
      fontWeight,
      loading = false,
      disabled,
      classes,
      loaderSize,
      ...props
    },
    ref
  ) => {
    if (!sizeVariant) {
      throw new TypeError("sizeVariant is required");
    }

    const loaderWidth = useMemo(() => {
      // Props size
      if (loaderSize) {
        return loaderSize;
      }

      // Default size
      return loaderVariant !== "dot" ? "1.25rem" : "0.875rem";
    }, [loaderSize, loaderVariant]);

    return (
      <StyledWrapperButton
        ref={ref}
        {...props}
        disabled={loading || disabled}
        $sizeVariant={sizeVariant}
        $size={size}
        $color={color}
        $variant={variant}
        $fullWidth={fullWidth}
        $justify={justify}
        $classes={classes?.root}
        $loading={loading}
        $loaderVariant={loaderVariant}
      >
        {startIcon && (
          <StyledStartIcon $size={size} $sizeVariant={sizeVariant}>
            {startIcon}
          </StyledStartIcon>
        )}

        {children && !loading && (
          <StyledWrapperChildren sizeVariant={sizeVariant} color={color} fontWeight={fontWeight} size={size}>
            {children}
          </StyledWrapperChildren>
        )}

        {loading && (
          <Loader variant={loaderVariant} width={loaderWidth} height={loaderWidth} animation="pulse1" />
        )}

        {endIcon && (
          <StyledEndIcon $size={size} $sizeVariant={sizeVariant}>
            {endIcon}
          </StyledEndIcon>
        )}
      </StyledWrapperButton>
    );
  }
);

export default Button;
