import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { AddCartProduct } from "@/recourses/recombee";

import { existProductCart } from "@utils/cartV2";
import debounce from "lodash/debounce";
import { cartStoresActions } from "@/store/cartStore/index.js";
import { type AppState } from "@/store";
import useAppSelector from "./useAppSelector";
import { checkProductHasStock } from "@/utils/product";
import { addToCart, removeFromCart } from "@/utils/analyticsV2";
import Analytics from "@/utils/Analytics";
import { selectCoupon } from "@/store/checkout/selector";

const selectItems = (state: AppState) => state.cart.items;
const selectItemsWithoutDiscount = (state: AppState) =>
  state.cart.items.filter((item) => !item.details.onSale);

const originalPrice = (item: Cart) => item?.price?.originalPrice ?? 0;
const discountedPrice = (item: Cart) => item?.price?.finalPrice ?? 0;
const maxQtyToOrder = (item: Cart) => item?.details.maxQtyToOrder ?? 0;
const quantityOrMaxOrder = (item: Cart) =>
  item?.quantity > maxQtyToOrder(item) ? maxQtyToOrder(item) : item?.quantity;

const selectTotalCountCart = createSelector([selectItems], (items) => {
  return items.reduce((acc, item) => {
    let quantity = item?.quantity ?? 0;

    if (item.stepQty >= 2) {
      quantity = Math.ceil(quantity / (item.stepQty ?? 1));
    }
    return acc + quantity;
  }, 0);
});

const selectSubTotal = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return acc + (quantityOrMaxOrder(item) ?? 0) * originalPrice(item);
  }, 0)
);

const selectDiscount = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return (
      acc +
      (item?.details?.onSale ? originalPrice(item) - discountedPrice(item) : 0) *
        (quantityOrMaxOrder(item) ?? 1)
    );
  }, 0)
);

const selectTotal = createSelector(
  [selectSubTotal, selectDiscount],
  (subTotal, discount) => subTotal - discount
);

const selectItemsStock = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return {
      ...acc,
      [item.product.sku]: checkProductHasStock(item),
    };
  }, {})
);

const selectTotalItemsWithoutDiscount = createSelector([selectItemsWithoutDiscount], (items) => {
  return items.reduce((acc, item) => {
    return acc + (quantityOrMaxOrder(item) ?? 0) * originalPrice(item);
  }, 0);
});

const useCart = () => {
  const dispatch = useDispatch();
  const items = useAppSelector(selectItems);
  const totalCountCart = useAppSelector(selectTotalCountCart);
  const subTotal = useAppSelector(selectSubTotal);
  const discount = useAppSelector(selectDiscount);
  const total = useAppSelector(selectTotal);
  const itemsStock: Record<string, boolean> = useAppSelector(selectItemsStock);
  const storeId = useAppSelector((state) => state.cart?.storeId);
  const totalItemsWithoutDiscount = useAppSelector(selectTotalItemsWithoutDiscount);
  const coupon = useAppSelector(selectCoupon);

  const itemWithDiscount = useMemo(() => {
    let discountValue = coupon?.discountValue ?? 0;
    if (coupon?.discountValueType === "percentage") {
      discountValue = totalItemsWithoutDiscount * (discountValue / 100);
    }

    return items.reduce<CartWithDiscount[]>((acc, item) => {
      if (item.details.onSale) {
        return [
          ...acc,
          {
            ...item,
            price: {
              ...item.price,
              cuponPrice: undefined,
              discountType: "promofit",
            },
          },
        ];
      }

      const itemTotalPrice = discountedPrice(item) * quantityOrMaxOrder(item);

      const proportion = itemTotalPrice / totalItemsWithoutDiscount;

      // Calculate the discount amount for the item based on its proportion
      let itemDiscountAmount = discountValue * proportion;

      // Check if the discount exceeds the maximum discount per item (which is the item's total price)
      const maxDiscountPerItem = itemTotalPrice;

      itemDiscountAmount = Math.min(itemDiscountAmount, maxDiscountPerItem);

      // Apply the discount to the item's unit price
      const discountPerUnit = itemDiscountAmount / item.quantity;

      return [
        ...acc,
        {
          ...item,
          price: {
            ...item.price,
            cuponPrice: {
              gross: discountedPrice(item) - discountPerUnit,
              type: coupon?.discountValueType ?? "",
              discountValue,
              discountPerUnit,
            },
            discountType: "coupon",
          },
        },
      ];
    }, []);
  }, [JSON.stringify(coupon), total, JSON.stringify(items), totalItemsWithoutDiscount]);

  const findItem = useCallback(
    (productId: number) => existProductCart(items, productId),
    [JSON.stringify(items)]
  );

  const handleAddMultipleCart = useCallback(
    (oldProducts: Cart[]) => {
      oldProducts.forEach((oldProduct) => {
        handleAddCart(oldProduct);
      });
    },
    [JSON.stringify(items), storeId]
  );

  const handleAddCart = useCallback(
    (oldProduct: Cart) => {
      const stepQty = oldProduct?.stepQty ?? 1;

      void AddCartProduct(String(oldProduct.product.id), {
        amount: stepQty,
        price: oldProduct.price.finalPrice,
        recommId: oldProduct?.recommId,
      });

      if (!findItem(Number(oldProduct.product.id))) {
        if (storeId !== undefined) {
          Analytics.AddToCart({
            productsInfo: {
              availability: {
                availability: oldProduct.details.available,
              },
              item: oldProduct,
              quantity: oldProduct.quantity,
              storeId: String(storeId),
            },
            cartProducts: items,
            recommId: oldProduct?.recommId,
          });
        }

        addToCart.eventProduct(oldProduct);
        dispatch(cartStoresActions.addProduct(oldProduct));
        return;
      }

      dispatch(
        cartStoresActions.changeQuantity({
          productId: Number(oldProduct.product.id),
          quantity: stepQty,
        })
      );
    },
    [JSON.stringify(items), storeId]
  );

  const handleIncrement = useCallback(
    (productId: number) => {
      const item = findItem(productId);
      const stepQty = item?.stepQty ?? 1;

      const quantity = stepQty * 1 + (item?.quantity ?? 0);

      if (item) {
        dispatch(cartStoresActions.changeQuantity({ productId, quantity }));
        void AddCartProduct(String(item.product.id), {
          amount: quantity,
          price: item?.price.finalPrice,
          recommId: item?.recommId,
        });
      }
    },
    [JSON.stringify(items)]
  );

  const handleDecrement = useCallback(
    (productId: number, callBack?: () => void) => {
      const item = existProductCart(items, productId);
      const stepQty = item?.stepQty ?? 1;
      const quantity = item?.quantity ?? 0;

      if (item) {
        if (quantity > stepQty) {
          dispatch(cartStoresActions.changeQuantity({ productId, quantity: quantity - stepQty }));
          void AddCartProduct(String(item.product.id), {
            amount: quantity - stepQty,
            price: item?.price.finalPrice,
            recommId: item?.recommId,
          });
          return;
        }
        if (quantity <= stepQty) {
          handleRemove(productId);
          callBack?.();
        }
      }
    },
    [JSON.stringify(items)]
  );

  const productsOutOfStock = useMemo(() => {
    return items.filter((item) => !item.details.available);
  }, [items]);

  const handleRemove = useCallback(
    (productId: number, callBack?: () => void) => {
      dispatch(cartStoresActions.removeProduct(productId));
      const product = items.find((item) => String(item.product.id) === String(productId));
      if (product) {
        removeFromCart.eventProduct(product);
      }
      callBack?.();
    },
    [JSON.stringify(items)]
  );

  const handleRemoves = useCallback((productIds?: string[], callBack?: () => void) => {
    if (!productIds || productIds.length === 0) {
      productIds = productsOutOfStock.map((product) => String(product.product.id));
    }
    dispatch(cartStoresActions.removeProducts(productIds));
    callBack?.();
  }, []);

  const handleChangeInput = useCallback(
    debounce((productId: number, quantity: string) => {
      const numQuantity = Number(quantity);
      if (!isNaN(numQuantity) && numQuantity >= 0) {
        const item = existProductCart(items, productId);
        dispatch(cartStoresActions.changeQuantity({ productId, quantity: numQuantity }));
        void AddCartProduct(String(item?.product.id), {
          amount: numQuantity,
          price: item?.price.finalPrice,
          recommId: item?.recommId,
        });
      }
    }, 50),
    []
  );

  const handleCleanCart = useCallback(() => {
    dispatch(cartStoresActions.emptyCart({}));
  }, [dispatch]);

  const removeProductsEmpty = () => {
    const productsEmpty = items.filter((item) => item.quantity === 0);
    const productIds = productsEmpty.map((product) => String(product.product.id));
    handleRemoves(productIds);
  };

  return {
    // states:
    items,
    total,
    subTotal,
    discount,
    totalCountCart,
    itemsStock,
    totalItemsWithoutDiscount,
    itemWithDiscount,
    coupon,
    // functions:
    handleAddCart,
    handleAddMultipleCart,
    handleRemove,
    handleIncrement,
    handleDecrement,
    handleChangeInput,
    handleCleanCart,
    handleRemoves,
    removeProductsEmpty,
  };
};

export default useCart;
