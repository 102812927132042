import type { CSSProperties } from "react";
import tw, { styled, css } from "twin.macro";

export const StyledWrapperModalAlert = styled.div<{ $maxWidth: CSSProperties["maxWidth"] }>`
  ${tw`w-full p-s flex flex-col gap-m`}
  ${({ $maxWidth }) =>
    $maxWidth
      ? css`
          max-width: ${$maxWidth};
        `
      : tw`max-w-[37.25rem]`}
`;

export const StyledWrapperButtonClose = styled.div`
  ${tw`w-full flex justify-end`}
`;
