import { type CSSProperties, useState, type ReactNode, useEffect, useRef, useId } from "react";
import { theme, type TwStyle } from "twin.macro";
import { type RuleSet } from "styled-components";
import AddIcon from "@iconsV2/Edit/add.svg?react";
import RemoveIcon from "@iconsV2/Edit/remove.svg?react";
import IconChevronTop from "@iconsV2/Arrow/chevron-top-normal.svg?react";
import IconChevronBottom from "@iconsV2/Arrow/chevron-bottom-normal.svg?react";
import useResizeObserver from "@/hooks/useResizeObserver";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import {
  StyledCard,
  StyledContent,
  StyledContentClose,
  StyledContentPadding,
  StyledDetails,
  StyledHeading,
  StyledSummary,
} from "./styles";
import Toggle from "../Toogle";

export interface ICardDismissible {
  title?: string | ReactNode;
  collapsedValue?: ReactNode;
  children?: ReactNode;
  defaultOpen?: boolean;
  contentClose?: ReactNode;
  withShadow?: boolean;
  withBorder?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  classes?: {
    root?: RuleSet<object> | TwStyle;
    head?: RuleSet<object> | TwStyle;
    content?: RuleSet<object> | TwStyle;
  };
  onChange?: (expanded: boolean) => void;
  active?: boolean;
  open?: boolean;
  activeHover?: boolean;
  activeColor?: CSSProperties["borderColor"];
  icon?: "default" | "arrows" | "switch" | "none";
  key?: any;
}

const CardDismissible = ({
  title,
  collapsedValue,
  classes,
  children,
  open,
  defaultOpen,
  contentClose,
  withShadow = false,
  withBorder = true,
  backgroundColor,
  onChange,
  active = false,
  activeHover = false,
  activeColor = theme`colors.neutral.60`,
  icon = "default",
}: ICardDismissible) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(open ?? defaultOpen ?? false);
  const [maxHeight, setMaxHeight] = useState(0); // Max height for the animation
  const isExpanded = open ?? expanded;
  const generatedId = useId();

  const handleToogle = (checked: boolean) => {
    setExpanded(checked);
    onChange?.(checked);
  };

  const handelExpanded = (e: React.SyntheticEvent<HTMLDetailsElement, Event>) => {
    const newValue = e.currentTarget.open;
    handleToogle(newValue);
  };

  // Listen for changes external of open prop
  useEffect(() => {
    if (open !== undefined) {
      setExpanded(open);
    }
  }, [open]);

  // Set the max height when the content height changes
  useResizeObserver({
    ref: contentRef,
    callback: () => {
      const newHeight = contentRef.current?.offsetHeight ?? 0;
      setMaxHeight(newHeight);
    },
  });

  return (
    <StyledCard
      activeColor={activeColor}
      classes={classes}
      withShadow={withShadow}
      withBorder={withBorder}
      active={active && isExpanded}
      activeHover={activeHover}
      $backgroundColor={backgroundColor}
    >
      <StyledDetails open={isExpanded} onToggle={handelExpanded} $maxHeight={maxHeight}>
        <StyledSummary>
          <span tw="w-full">
            <StyledHeading $classes={classes?.head} $headingWithBorder={isExpanded && withBorder}>
              {typeof title === "string" ? <Heading type="h6">{title}</Heading> : title}

              <div tw="flex items-center gap-s">
                {typeof collapsedValue === "string" && collapsedValue.length > 0 && !isExpanded && (
                  <Paragraph sizeVariant="sm" tw="max-w-[3.25rem] medium:(max-w-[6.25rem]) line-clamp-1">
                    {collapsedValue}
                  </Paragraph>
                )}
                {collapsedValue && typeof collapsedValue !== "string" && collapsedValue}

                {icon === "default" && (
                  <>
                    {isExpanded ? <RemoveIcon width={24} height={24} /> : <AddIcon width={24} height={24} />}
                  </>
                )}
                {icon === "arrows" && (
                  <>
                    {isExpanded ? (
                      <IconChevronTop width={24} height={24} />
                    ) : (
                      <IconChevronBottom width={24} height={24} />
                    )}
                  </>
                )}

                {icon === "switch" && (
                  <span>
                    <Toggle
                      id={`switch-${generatedId}`}
                      color="neutral"
                      checked={isExpanded}
                      onChange={handleToogle}
                    />
                  </span>
                )}
              </div>
            </StyledHeading>
          </span>
        </StyledSummary>
      </StyledDetails>

      <StyledContent id="card-dismissable-content">
        <StyledContentPadding ref={contentRef} $withBorder={withBorder} $classes={classes?.content}>
          {children}
        </StyledContentPadding>
      </StyledContent>

      {contentClose && !isExpanded && <StyledContentClose>{contentClose}</StyledContentClose>}
    </StyledCard>
  );
};

export default CardDismissible;
