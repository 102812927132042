import { useMemo, useRef } from "react";
import tw from "twin.macro";
import Heading from "@atoms/Heading";
import Modal from "@atoms/Modal";
import Button from "@atoms/Button";
import IconClose from "@iconsV2/Edit/close.svg?react";

import {
  StyledContent,
  StyledContentAllProducts,
  StyledContentBottom,
  StyledContentHeading,
  StyledIconShoppingCart,
  StyledLoaderLoading,
  StyledWrapperLoader,
} from "./styles";
import { CheckoutEnum } from "@/recourses/queryKeys";
import { useQuery } from "@tanstack/react-query";
import useCart from "@/hooks/useCartV2";
import { getCartAvailableProducts } from "@/services/checkout";
import RecomendedOneProduct from "./RecomendedOneProduct";
import RecomendedVariusProducts from "./RecomendedVariusProducts";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import Notification from "@/components/Atoms/Notification";

interface ModalItemsOutOfStockProps {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit?: () => void;
  storeId?: number;
  onlyClose?: boolean;
  closeOutSise?: boolean;
}

const ModalItemsOutOfStock = ({
  isOpen,
  handleClose,
  storeId,
  onSubmit,
  onlyClose = false,
  closeOutSise = true,
}: ModalItemsOutOfStockProps) => {
  const { items, handleRemoves } = useCart();
  const isMedium = useMediaQuery("(min-width: {medium})");

  const itemsRef = useRef(items);

  const { data: cartAvailables, isFetching } = useQuery({
    queryKey: [CheckoutEnum.getCartAvailableProducts, storeId, JSON.stringify(itemsRef.current)],
    queryFn: async () => {
      const productsId = itemsRef.current.map((item) => String(item.product.sku)).join(",");
      return await getCartAvailableProducts(Number(storeId), productsId);
    },
    enabled: isOpen,
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const productsWithAvailabilityInfo = useMemo(() => {
    if (!cartAvailables?.payload) return [];

    return itemsRef.current.map((item) => {
      const availabilityInfo = cartAvailables.payload.find(
        (availableItem) => String(availableItem.sku) === String(item.product.sku)
      );

      return {
        ...item,
        details: {
          ...item.detail,
          available: availabilityInfo?.is_available ?? false,
        },
      };
    });
  }, [itemsRef.current, cartAvailables?.payload]);

  const productsOutOfStock = useMemo(() => {
    return productsWithAvailabilityInfo.filter((item) => !item.details.available);
  }, [productsWithAvailabilityInfo]);

  const isOneProduct = productsOutOfStock.length === 1;

  const handleConinue = async () => {
    handleRemoves(
      productsOutOfStock.map((item) => item.product.id),
      () => {
        onSubmit?.();
        handleClose();
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => (onlyClose ? handleClose() : onSubmit?.())}
      closeOutSise={closeOutSise}
      fullScreen={!isMedium}
      classes={{
        card: tw`h-screen p-0 w-full grid grid-rows-["auto"] medium:(max-w-[35rem] max-h-[80vh]) large:(max-h-[42rem]) relative`,
        backdrop: tw`z-[53]`,
      }}
    >
      {isFetching && (
        <StyledWrapperLoader>
          <StyledLoaderLoading />
        </StyledWrapperLoader>
      )}
      <StyledContent tw="flex flex-row h-max sticky top-0">
        <StyledContentHeading>
          <StyledIconShoppingCart />
          <Heading type="h5" tw="text-sm">
            {isOneProduct
              ? "Este producto no está disponible por el momento"
              : `Estos ${productsOutOfStock.length} productos no están disponibles por el momento`}
          </Heading>
        </StyledContentHeading>

        {closeOutSise && (
          <Button
            type="button"
            sizeVariant="lg"
            variant="text"
            color="secondary"
            startIcon={<IconClose tw="h-6 w-6 cursor-pointer" />}
            onClick={() => (onlyClose ? handleClose() : onSubmit?.())}
          />
        )}
      </StyledContent>

      {isOneProduct ? (
        <RecomendedOneProduct product={productsOutOfStock[0]} storeId={storeId!} items={itemsRef.current} />
      ) : (
        <StyledContentAllProducts>
          {productsOutOfStock.map((item) => (
            <RecomendedVariusProducts
              key={item.product.id}
              product={item}
              storeId={storeId!}
              items={itemsRef.current}
            />
          ))}
        </StyledContentAllProducts>
      )}

      <StyledContentBottom>
        <Notification
          type="warning"
          messages="Los producos sin disponibilidad serán eliminados de tu carrito de compras"
        />
        <Button sizeVariant="md" variant="outlined" color="secondary" onClick={handleConinue}>
          Continuar
        </Button>
      </StyledContentBottom>
    </Modal>
  );
};

export default ModalItemsOutOfStock;
