import { useQuery } from "@tanstack/react-query";

import { getCreditsAvailable, getReferralCreditsAvailable } from "@/services/credits";
import useSessionContext from "./useSesionContext";
import { CreditsEnum } from "@/recourses/queryKeys";
import { useMemo } from "react";

interface IUseCredits {
  creditsAvailable?: boolean;
  referralCreditsAvailable?: boolean;
}

export default function useCredits(props?: IUseCredits) {
  const { isAthenticated, userToken } = useSessionContext();

  const creditsAvailable = useQuery({
    queryKey: [CreditsEnum.creditsAvailable, userToken],
    queryFn: getCreditsAvailable,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    staleTime: 6000 * 60 * 10,
    enabled: Boolean(userToken) && props?.creditsAvailable && isAthenticated(),
  });

  const referralCreditsAvailable = useQuery({
    queryKey: [CreditsEnum.referralCreditsAvailable, userToken],
    queryFn: getReferralCreditsAvailable,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    staleTime: 6000 * 60 * 10,
    enabled: Boolean(userToken) && props?.referralCreditsAvailable && isAthenticated(),
  });

  const totalCredits = useMemo(() => {
    return Number(creditsAvailable.data?.gross ?? 0) + Number(referralCreditsAvailable.data?.gross ?? 0);
  }, [creditsAvailable.data?.gross, referralCreditsAvailable.data?.gross]);

  return {
    creditsAvailable,
    referralCreditsAvailable,
    totalCredits,
  };
}
