import { createContext, useState, useReducer, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";

import { getDeliveryPrice } from "../../services/price";
import { isValidateServicePrice, cleaningNullValue } from "../../utils/orders";
import { useMembership } from "../../hooks";
import { toast } from "react-toastify";
import { validateCoupon } from "../../services/coupons";
import { getProductsSummaryInfo, getService } from "./utils";
import useCart from "@/hooks/useCartV2";
import useAppSelector from "@/hooks/useAppSelector";
import useCredits from "@/hooks/useCredits";
import dayjs from "@utils/dayjs";
import { Outlet } from "react-router-dom";

const checkoutEmpty: ICheckout = {
  coupon: null,
  service: null,
  pickupStore: null,
  payment: null,
  useGift: false,
  useFitpoint: null,
  useFriendPoints: null,
  sendWhatsApp: true,
  gift_from: "",
  gift_for: "",
  gift_comment: "",
  useBasket: false,
  basket_from: "",
  basket_for: "",
  basket_comment: "",
  additionalComment: "",
};

const CheckoutContextEmpty = {
  checkout: checkoutEmpty,
  date: "",
  subtotal: 0,
  discount: 0,
  fitpoints: undefined,
  isDisabledNextStep: true,
  stepNumber: 0,
};

interface CheckoutProviderProps {
  children?: React.ReactNode;
}

const checkoutContext = createContext<CheckoutContext>(CheckoutContextEmpty);

const reducer = (state: ICheckout, pair): ICheckout => ({ ...state, ...pair });

const CheckoutProvider = ({ children }: CheckoutProviderProps): React.ReactElement => {
  /* Use useReducer tu update context in real time  */
  const [checkout, setCheckout] = useReducer(reducer, checkoutEmpty);

  const location = useAppSelector((state) => state.cart.location);
  const storeId = useAppSelector((state) => state.cart.storeId);

  const { itemWithDiscount, totalItemsWithoutDiscount } = useCart();

  const [hasMembership] = useMembership();

  // Context States
  const [nextStep, setNextStep] = useState<string | (() => void)>("");
  const [isDisabledNextStep, setIsDisabledNextStep] = useState<boolean>(true);
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [shippingServicePrice, setShippingServicePrice] = useState<getDeliveryPriceResponse | undefined>(
    undefined
  );
  const [optionFlow, setOptionFlow] = useState<"Delivery" | "Pick up">((): "Delivery" | "Pick up" => {
    return !!location && !!location?.isPickUp ? "Pick up" : "Delivery";
  });

  // set date if service is delivery
  const date = useMemo(() => {
    if (checkout?.service?.day && checkout.service?.hours) {
      const dateString = dayjs(checkout.service.day.date).format("YYYY-MM-DD");
      const timeString = checkout.service.hours.start;
      return dayjs(`${dateString}T${timeString}`).format("YYYY-MM-DDTHH:mm:ssZ");
    }
    return null;
  }, [checkout.service?.day, checkout.service?.hours]);

  // usefull queries to get data

  // get fitpoints and friendpoint
  const { creditsAvailable, referralCreditsAvailable } = useCredits({
    creditsAvailable: true,
    referralCreditsAvailable: true,
  });

  const fitpoints = creditsAvailable.data;
  const friendpointInfo = referralCreditsAvailable.data;

  //  mutation to getService delivery price
  const mutationDeliveryPrice = useMutation({
    mutationFn: getDeliveryPrice,
    onSuccess: (DeliveryPriceData: getDeliveryPriceResponse) => {
      setShippingServicePrice(DeliveryPriceData);
    },
    onError: () => {
      toast.error("Error al calcular el precio de envio", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setShippingServicePrice(undefined);
    },
  });

  // get Purchase summary info
  const {
    productsSubtotal, // Get products subtotal without discounts
    productDiscoutedTotal, // Get products total with discounts (regular and membership)
    productsDiscount, // Get products discount (regular and membership)
    creditsSummary, // Get credits summary (FitPoints)
    referralCreditsSummary, // Get referral credits summary (FriendPoints)
    // couponDiscount, // Get coupon discount
    ProductsTotal, // Get products total with discounts (regular and membership), credits and coupon
  } = getProductsSummaryInfo(itemWithDiscount, fitpoints, friendpointInfo, checkout.coupon, {
    hasMembership,
    useCoupon: !!checkout.coupon,
    useFitpoints: !!checkout.useFitpoint,
    useFriendPoints: !!checkout.useFriendPoints,
  });
  const servicePrice = getService(shippingServicePrice);
  const total = ProductsTotal + (!!servicePrice && servicePrice > 0 ? servicePrice : 0); // + (checkout.useBasket ? + 10000 : 0)

  const cuponAmount = useMemo(() => {
    if (!checkout.coupon?.is_valid) {
      return 0;
    }

    return itemWithDiscount.reduce((acc, item) => {
      if (checkout.coupon?.is_valid) {
        return acc + (checkout.coupon.discount_value ?? 0) * item.quantity;
      }
      return acc;
    }, 0);
  }, [JSON.stringify(itemWithDiscount), checkout?.coupon?.is_valid]);

  // Auxiliar functions
  const buildServicePriceData = (): any => {
    const fields = cleaningNullValue({
      id: checkout.service?.item.id,
      scheduled_at: date,
      total: Math.trunc(productDiscoutedTotal), // preguntar a Ale
      address: location?.id,
      coupon: checkout.coupon && checkout.coupon.is_valid ? checkout.coupon.id : null,
      lat: location?.lat ? location.lat.toFixed(12).toString() : "",
      lng: location?.lng ? location.lng.toFixed(12).toString() : "",
      items: itemWithDiscount.map((productItem: Cart): number => {
        return productItem.product.sku;
      }),
    });

    return fields;
  };

  useEffect(() => {
    // get service Price use effect
    const servicePriceData = buildServicePriceData();
    if (isValidateServicePrice(servicePriceData)) {
      if (checkout.coupon) {
        void (async () => {
          // Validate coupon
          !!storeId &&
            !!checkout.coupon &&
            checkout.coupon.is_valid &&
            validateCoupon(checkout.coupon.code, storeId, productsSubtotal)
              .then(() => {
                // if coupon is valid, get coupon discount
                void mutationDeliveryPrice.mutate(buildServicePriceData());
              })
              .catch(() => {
                // if coupon is not valid, remove coupon and get service price
                setCheckout(Object.assign(checkout, { coupon: null }));
                void mutationDeliveryPrice.mutate(buildServicePriceData());
              });
        })();
      } else {
        // if there is no coupon, get service price
        void mutationDeliveryPrice.mutate(buildServicePriceData());
      }
    } else {
      setShippingServicePrice(undefined);
    }
  }, [
    JSON.stringify(checkout.service),
    checkout?.coupon?.code,
    JSON.stringify(itemWithDiscount),
    storeId,
    productsSubtotal,
  ]);

  useEffect(() => {
    if (checkout.coupon) {
      // if has coupon, valid on any change on checkout and add products
      void (async () => {
        !!storeId &&
          !!checkout.coupon &&
          checkout.coupon.is_valid &&
          validateCoupon(checkout.coupon.code, storeId, productsSubtotal).catch(() => {
            setCheckout(Object.assign(checkout, { coupon: null }));
          });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(checkout.coupon), JSON.stringify(itemWithDiscount), storeId]);

  const isValidateNextStep = (callback): void => {
    if (!callback) return setIsDisabledNextStep(false);
    if (callback()) return setIsDisabledNextStep(false);
    return setIsDisabledNextStep(true);
  };

  const getOrderParams = (IPData?: string | null): CreateOrdenType => {
    return {
      shipping_address: checkout.service?.item.appointment_type === "on_site" ? null : location?.id,
      items: itemWithDiscount.map((item) => ({
        store_product: String(item.product.sku),
        quantity: item.quantity,
        comment: item.product.comment ? item.product.comment : "",
        recommendation_id: item?.recommId ?? "",
      })),
      store: storeId,
      coupon: checkout.coupon && checkout.coupon.is_valid ? checkout.coupon.id : undefined,
      service: checkout.service ? checkout.service.item.id : undefined,
      user_payment_method: checkout.payment ? checkout.payment.id : undefined,
      should_use_credit_available: !!checkout.useFitpoint,
      should_use_referral_credit_available: !!checkout.useFriendPoints,
      scheduled_at: date,
      user_comment: checkout.additionalComment ? `${checkout.additionalComment}` : "",
      is_gift: checkout.useBasket ? false : checkout.useGift,
      gift_from: checkout.useGift && !checkout.useBasket && !!checkout.gift_from ? checkout.gift_from : null,
      gift_for: checkout.useGift && !checkout.useBasket && !!checkout.gift_for ? checkout.gift_for : null,
      gift_comment:
        checkout.useGift && !checkout.useBasket && !!checkout.gift_comment ? checkout.gift_comment : null,
      is_basket: checkout.useGift ? false : checkout.useBasket,
      basket_from:
        checkout.useBasket && !checkout.useGift && !!checkout.basket_from ? checkout.basket_from : null,
      basket_for:
        checkout.useBasket && !checkout.useGift && !!checkout.basket_for ? checkout.basket_for : null,
      basket_comment:
        checkout.useBasket && !checkout.useGift && !!checkout.basket_comment ? checkout.basket_comment : null,
      should_receive_notifications: checkout.sendWhatsApp,
      meta_data: {
        user_agent: navigator.userAgent,
        ip_address: IPData ?? "8.8.8.8",
      },
    };
  };

  return (
    <checkoutContext.Provider
      value={{
        optionFlow,
        setOptionFlow,
        isLoadingGetServicePrice: mutationDeliveryPrice.isPending,
        getOrderParams,
        productsTotal: productsSubtotal,
        shippingServicePrice,
        productsDiscount: productsDiscount.regularDiscounts,
        productsDiscountMembership: productsDiscount.membershipDiscounts,
        productsSubtotal: productDiscoutedTotal,
        servicePrice,
        couponDiscount: cuponAmount,
        referralCreditsSummary,
        subtotal: total,
        creditsDiscount: creditsSummary.creditsAvailable,
        total,
        date,
        checkout,
        setCheckout,
        stepNumber,
        setStepNumber,
        fitpoints,
        nextStep,
        setNextStep,
        isDisabledNextStep,
        setIsDisabledNextStep,
        isValidateNextStep,
        totalItemsWithoutDiscount,
      }}
    >
      {children ?? <Outlet />}
    </checkoutContext.Provider>
  );
};

export { checkoutContext, CheckoutProvider };
