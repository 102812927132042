import { createAction } from "@reduxjs/toolkit";
import { queryClient } from "@/recourses/queryClient";
import {
  AddressEnum,
  CheckoutEnum,
  CreditsEnum,
  PaymentMethodsEnum,
  StoresEnum,
} from "@/recourses/queryKeys";

const emptyCheckout = createAction("checkout/emptyCheckout", () => {
  const queryCache = queryClient.getQueryCache();
  const allQueriesCache = queryCache.findAll();

  const queryKeys = [
    "servicePrice",
    AddressEnum.getAddresses,
    StoresEnum.getPickUpStores,
    CheckoutEnum.getQuickStart,
    CheckoutEnum.getAvaliableStores,
    CheckoutEnum.getCartAvailableProducts,
    CheckoutEnum.getShippingInfo,
    PaymentMethodsEnum.paymentsAvailable,
    PaymentMethodsEnum.paymentMethod,
    PaymentMethodsEnum.paymentPseUrl,
    CreditsEnum.creditsAvailable,
    CreditsEnum.referralCreditsAvailable,
  ];

  queryKeys.forEach((query) => {
    const queryhas = allQueriesCache.find((q) => q.queryKey.includes(query));
    if (queryhas) {
      queryClient.removeQueries({
        queryKey: queryhas.queryKey,
        exact: false,
      });
    }
  });

  return {
    payload: null,
  };
});

export const storeGlobalActions = {
  emptyCheckout,
};
