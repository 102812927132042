import { Navigate, Outlet, type RouteObject } from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import RootLayout from "@/components/Layouts/RootLayout";
import SentryRouteErrorFallback from "@/components/Layouts/SentryRouteErrorFallback";
import Error404 from "@/Pages/Error404";
import { IS_MAINTENANCE, IS_PROD } from "@/recourses/constants";

import { PrivateRoute } from "./PrivateRoute";
import { maintenanceRoutes } from "./routes";
import { publicRoutes, oldPublicRoutes } from "./public";
import { privateRoutes, oldPrivateRoutes } from "./private";
import { createBrowserRouterWithRetry } from "./utils";

const routes: RouteObject[] = IS_MAINTENANCE
  ? [
      ...maintenanceRoutes,
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ]
  : [
      {
        path: "/",
        element: <RootLayout />,
        errorElement: IS_PROD && <SentryRouteErrorFallback />,
        children: [
          // Old public ruotes
          ...oldPublicRoutes,
          // New public routes
          {
            path: "/",
            lazy: async () => {
              const { default: MainLayout } = await import("@/components/Layouts/MainLayout");
              return {
                element: <MainLayout />,
              };
            },
            children: publicRoutes,
          },
          // Private routes
          {
            path: "/",
            element: (
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            ),
            children: [...oldPrivateRoutes, ...privateRoutes],
          },
          {
            path: "*",
            element: <Error404 />,
          },
        ],
      },
    ];

export const wrapperBrowserRouter = wrapCreateBrowserRouter(createBrowserRouterWithRetry);

export default wrapperBrowserRouter(routes);
