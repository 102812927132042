interface IResultErrorAPI {
  message: string;
  key: string;
}
const KEY = "root";

// Get an error from a api response
// Return the error message, not an alert
export const validateErrorsAPI = (
  error?: string | string[] | Record<string, string | string[] | Record<string, string>> | undefined,
  defaultKey?: string
): IResultErrorAPI => {
  if (typeof error === "string") {
    return {
      message: error,
      key: defaultKey ?? KEY,
    };
  }

  // Validates if the error message is an object to loop through it and then display it
  if (typeof error === "object" && Object?.keys(error).length > 0) {
    const errValues = Object.values(error);
    const errKeys = Object.keys(error);

    if (Array.isArray(errValues?.[0]) && errValues?.[0].length > 0) {
      return {
        message:
          errValues?.[0]?.[0] === "timed out"
            ? `(${errValues[0]?.[0]}) Hubo un problema al procesar tu petición. Por favor intenta nuevamente más tarde`
            : errValues?.[0]?.[0],
        key: errKeys[0],
      };
    }

    if (typeof errValues?.[0] === "object" && Object?.keys(errValues?.[0]).length > 0) {
      return validateErrorsAPI(errValues?.[0], defaultKey ?? errKeys?.[0]);
    }

    return { message: (errValues?.[0] as string) ?? "", key: errKeys[0] };
  }

  // Validates if the error message is an array to show in order
  if (Array.isArray(error) && error.length > 0) {
    return { message: error[0], key: defaultKey ?? KEY };
  }

  return {
    message: "Hubo un problema al procesar tu petición. Por favor intenta nuevamente más tarde.",
    key: defaultKey ?? KEY,
  };
};
