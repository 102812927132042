import { type AppState } from "@/store";
import { getInfoCoupon } from "@/utils/cartV2";
import { checkProductHasStock } from "@/utils/product";
import { createSelector } from "@reduxjs/toolkit";

const originalPrice = (item: Cart) => item?.price?.originalPrice ?? 0;
const discountedPrice = (item: Cart) => item?.price?.finalPrice ?? 0;
const maxQtyToOrder = (item: Cart) => item?.details.maxQtyToOrder ?? 0;
const quantityOrMaxOrder = (item: Cart) =>
  item?.quantity > maxQtyToOrder(item) ? maxQtyToOrder(item) : item?.quantity;

const selectItems = createSelector([(state: AppState) => state.cart.items], (items) => items ?? []);

const selectTotalCountCart = createSelector([selectItems], (items) => {
  return items.reduce((acc, item) => {
    let quantity = item?.quantity ?? 0;

    if (item.stepQty >= 2) {
      quantity = Math.ceil(quantity / (item.stepQty ?? 1));
    }
    return acc + quantity;
  }, 0);
});

const selectItemsWithoutDiscount = createSelector([selectItems], (items) => {
  return items.filter((item) => !item.details.onSale);
});

const selectTotalItemsWithoutDiscount = createSelector([selectItemsWithoutDiscount], (items) => {
  return items.reduce((acc, item) => {
    return acc + (quantityOrMaxOrder(item) ?? 0) * originalPrice(item);
  }, 0);
});

const selectItemsWithDiscount = createSelector([selectItems], (items) => {
  return items.filter((item) => item.details.onSale);
});

const selectSubTotal = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return acc + (quantityOrMaxOrder(item) ?? 0) * originalPrice(item);
  }, 0)
);

const selectDiscount = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return (
      acc +
      (item?.details?.onSale ? originalPrice(item) - discountedPrice(item) : 0) *
        (quantityOrMaxOrder(item) ?? 1)
    );
  }, 0)
);

const selectCoupon = createSelector(
  [(state: AppState) => state.checkout.coupon, selectTotalItemsWithoutDiscount],
  (coupon, totalItemsWithoutDiscount) => {
    return getInfoCoupon(coupon, totalItemsWithoutDiscount);
  }
);

const selectProcessedItems = createSelector(
  [selectItems, selectCoupon, selectTotalItemsWithoutDiscount],
  (items, coupon, totalItemsWithoutDiscount) => {
    const discountValue = coupon?.finalValuePrice ?? 0;

    return items.reduce<ProcessedItems[]>((acc, item) => {
      if (item.details.onSale) {
        return [
          ...acc,
          {
            ...item,
            price: {
              ...item.price,
              coupon: undefined,
              discountType: "promofit",
            },
          },
        ];
      }

      if (!coupon?.isValid || item.quantity === 0) {
        return [
          ...acc,
          {
            ...item,
            price: {
              ...item.price,
              coupon: undefined,
              discountType: "",
            },
          },
        ];
      }

      const itemTotalPrice = discountedPrice(item) * quantityOrMaxOrder(item);

      const proportion = itemTotalPrice / totalItemsWithoutDiscount;

      // Calculate the discount amount for the item based on its proportion
      let itemDiscountAmount = discountValue * proportion;

      // Check if the discount exceeds the maximum discount per item (which is the item's total price)
      const maxDiscountPerItem = itemTotalPrice;

      itemDiscountAmount = Math.min(itemDiscountAmount, maxDiscountPerItem);

      // Apply the discount to the item's unit price
      const discountPerUnit = itemDiscountAmount / item.quantity;

      const finalPrice = discountedPrice(item) - discountPerUnit;
      const discount = originalPrice(item) - finalPrice;

      return [
        ...acc,
        {
          ...item,
          price: {
            ...item.price,
            coupon: {
              type: coupon?.discountValueDisplay ?? "",
              discountValue,
              discountPerUnit,
              gross: discountValue,
            },
            discount,
            discountType: "coupon",
            finalPrice: discountedPrice(item) - discountPerUnit,
          },
        },
      ];
    }, []);
  }
);

const selectTotal = createSelector([selectSubTotal, selectDiscount], (subTotal, discount) => {
  return subTotal - discount;
});

const selectLocation = createSelector([(state: AppState) => state.checkout], (checkout) => {
  if (checkout.pickupStore) {
    return {
      isPickup: true,
      ...checkout.pickupStore,
    };
  }

  return {
    isPickup: false,
    ...checkout.address,
  };
});

const selectItemsStock = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return {
      ...acc,
      [item.product.sku]: checkProductHasStock(item),
    };
  }, {})
);

export {
  selectItems,
  selectItemsWithoutDiscount,
  selectItemsWithDiscount,
  selectTotalItemsWithoutDiscount,
  selectSubTotal,
  selectDiscount,
  selectCoupon,
  selectProcessedItems,
  selectTotal,
  selectLocation,
  originalPrice,
  discountedPrice,
  maxQtyToOrder,
  quantityOrMaxOrder,
  selectItemsStock,
  selectTotalCountCart,
};
