import { Navigate, type RouteObject } from "react-router-dom";

export const maintenanceRoutes: RouteObject[] = [
  {
    path: "/",
    lazy: async () => {
      const Maintenance = (await import("@/Pages/Maintenance")).default;
      return {
        element: <Maintenance />,
      };
    },
  },
];

export const oldCheckoutRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to="/checkout-old/confirm-cart" />,
  },
  {
    path: "confirm-cart",
    lazy: async () => {
      const { ConfirmCart } = await import("@/screens/Checkout/ConfirmCart");
      return {
        element: <ConfirmCart />,
      };
    },
  },
  {
    path: "confirm-payment",
    lazy: async () => {
      const { ConfirmPayment } = await import("@/screens/Checkout/ConfirmPayment");
      return {
        element: <ConfirmPayment />,
      };
    },
  },
  {
    path: "confirm-checkout",
    lazy: async () => {
      const { ConfirmCheckout } = await import("@/screens/Checkout/ConfirmCheckout");
      return {
        element: <ConfirmCheckout />,
      };
    },
  },
];

export const checkoutRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="review" replace />,
  },
  {
    path: "address",
    lazy: async () => {
      const { default: CheckoutAddress } = await import("@/Pages/CheckoutAddress");
      return {
        element: <CheckoutAddress />,
      };
    },
  },
  {
    path: "shipping",
    lazy: async () => {
      const { default: CheckoutShippingDate } = await import("@/Pages/CheckoutShippingDate");
      return {
        element: <CheckoutShippingDate />,
      };
    },
  },
  {
    path: "payment",
    lazy: async () => {
      const { default: CheckoutPaymentMethods } = await import("@/Pages/CheckoutPaymentMethods");
      return {
        element: <CheckoutPaymentMethods />,
      };
    },
  },
  {
    path: "pse-payment/:orderId",
    lazy: async () => {
      const { default: CheckoutPSEPayment } = await import("@/Pages/CheckoutPSEPayment");
      return {
        element: <CheckoutPSEPayment />,
      };
    },
  },
  {
    path: "invoice",
    lazy: async () => {
      const { default: CheckoutInvoice } = await import("@/Pages/CheckoutInvoice");
      return {
        element: <CheckoutInvoice />,
      };
    },
  },
  {
    path: "review",
    lazy: async () => {
      const { default: CheckoutReview } = await import("@/Pages/CheckoutReview");
      return {
        element: <CheckoutReview />,
      };
    },
  },
  {
    path: "success",
    lazy: async () => {
      const { default: CheckoutSuccess } = await import("@/Pages/CheckoutSuccess");
      return {
        element: <CheckoutSuccess />,
      };
    },
  },
  {
    path: "*",
    element: <Navigate to="/checkout" />,
  },
];

export const newProfileRoutes: RouteObject[] = [
  {
    path: "personal-data",
    lazy: async () => {
      const { default: PersonalData } = await import("@/Pages/PersonalData");
      return {
        element: <PersonalData />,
      };
    },
  },
  {
    path: "shipping-address",
    lazy: async () => {
      const { default: ShippingAddress } = await import("@/Pages/ShippingAddress");
      return {
        element: <ShippingAddress />,
      };
    },
  },
  {
    path: "security/password-change",
    lazy: async () => {
      const { default: PasswordChange } = await import("@/Pages/PasswordChange");
      return {
        element: <PasswordChange />,
      };
    },
  },
  {
    path: "shipping-address/new",
    lazy: async () => {
      const { default: ShippingAddressNew } = await import("@/Pages/ShippingAddressNew");
      return {
        element: <ShippingAddressNew />,
      };
    },
  },
  {
    path: "payment-methods",
    lazy: async () => {
      const { default: PaymentMethods } = await import("@/Pages/PaymentMethods");
      return {
        element: <PaymentMethods />,
      };
    },
  },
  {
    path: "payment-methods/add-card",
    lazy: async () => {
      const { default: CardAdd } = await import("@/Pages/CardAdd");
      return {
        element: <CardAdd />,
      };
    },
  },
  {
    path: "payment-methods/add-nequi",
    lazy: async () => {
      const { default: NequiAdd } = await import("@/Pages/NequiAdd");
      return {
        element: <NequiAdd />,
      };
    },
  },
  {
    path: "update-phone",
    lazy: async () => {
      const { default: UpdatePhone } = await import("@/Pages/UpdatePhone");
      return {
        element: <UpdatePhone />,
      };
    },
  },
  {
    path: "shipping-address/new",
    lazy: async () => {
      const { default: ShippingAddressNew } = await import("@/Pages/ShippingAddressNew");
      return {
        element: <ShippingAddressNew />,
      };
    },
  },
  {
    path: "security",
    lazy: async () => {
      const { default: Security } = await import("@/Pages/Security");
      return {
        element: <Security />,
      };
    },
  },
];
