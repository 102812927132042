import { useState, useEffect, useMemo } from "react";
import Caption from "@/components/Atoms/Caption";
import { StyledBottomNotificationHeader } from "./styles";
import tw, { css, theme } from "twin.macro";
import { Link } from "react-router-dom";
import useModalAuth from "@/hooks/useModalAuth";
import useSesionContext from "@/hooks/useSesionContext";

type event = "show_register_modal";
interface Notification {
  text: string;
  bg: string;
  color?: string;
  to?: string;
  event?: event;
}

interface BottomNotificationHeaderProps {
  duration?: number;
}

const BottomNotificationHeader = ({ duration = 10000 }: BottomNotificationHeaderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { handleOpenModalAuth } = useModalAuth();
  const { isAthenticated } = useSesionContext();
  const isAuthenticatedUser = isAthenticated();

  const notifications = useMemo(() => {
    const initialNotifications: Notification[] = [
      {
        text: "Descubre nuestras sedes, horarios, ubicaciones y todos los servicios para ti.",
        bg: theme`colors.secondary.90`,
        to: "/stores",
      },
    ];
    // Ocultar notificación de registro
    // if (!isAuthenticatedUser) {
    //   initialNotifications.push({
    //     text: "Únete ahora y disfruta de descuentos exclusivos",
    //     bg: theme`colors.primaryV2.90`,
    //     event: "show_register_modal",
    //   });
    // }

    return initialNotifications;
  }, [isAuthenticatedUser]);

  useEffect(() => {
    if (notifications.length <= 1) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, duration);

    return () => clearInterval(interval);
  }, [notifications, duration]);

  if (notifications.length === 0) {
    return null;
  }

  const handleNotificationEvent = (event: event) => {
    if (!event) return;

    if (event === "show_register_modal") {
      handleOpenModalAuth({ initialStep: "phoneNumber" });
    }
  };
  const currentNotification = notifications[currentIndex];

  return (
    <StyledBottomNotificationHeader
      $backgroundColor={currentNotification?.bg}
      as={currentNotification?.to ? Link : "div"}
      to={currentNotification?.to}
      title={currentNotification?.to ? "Ver más" : undefined}
      target={currentNotification?.to ? "_blank" : undefined}
      rel={currentNotification?.to ? "noopener noreferrer" : undefined}
      onClick={
        currentNotification?.event ? () => handleNotificationEvent(currentNotification.event!) : undefined
      }
    >
      <Caption
        fontWeight="600"
        color={currentNotification?.color ?? theme`colors.neutral.10`}
        css={css`
          ${currentNotification?.to ?? currentNotification?.event
            ? tw`cursor-pointer hover:(underline underline-offset-1)`
            : tw`cursor-default`}
          ${tw`max-w-screen-large text-center`}
        `}
      >
        {currentNotification?.text}
      </Caption>
    </StyledBottomNotificationHeader>
  );
};

export default BottomNotificationHeader;
