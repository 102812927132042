import CategoryButton, { type ICategoryButton } from "@/components/Atoms/CategoryButton";
import { type TSizeVariant } from "@/types/common";
import { Link } from "react-router-dom";
import tw, { css } from "twin.macro";
import IconLabelTag from "@iconsV2/Interrface/label-tag-01.svg?react";

interface HeaderPrimaryLinksProps {
  onClick?: () => void;
  sizeVariant?: TSizeVariant;
  otherLinks?: React.ReactNode;
  displayOtherLinksOnly?: boolean;
  showPromoButtonFirst?: boolean;
  classes: ICategoryButton["classes"];
}

const HeaderPrimaryLinks = ({
  onClick,
  sizeVariant = "md",
  otherLinks,
  displayOtherLinksOnly = false,
  showPromoButtonFirst,
  classes,
}: HeaderPrimaryLinksProps) => {
  return (
    <>
      {!displayOtherLinksOnly && (
        <CategoryButton
          label="Nuestra marca"
          title="Nuestra marca"
          color="secondary"
          as={Link}
          to="/c/nuestra-marca"
          onClick={onClick}
          sizeVariant={sizeVariant}
          classes={classes}
          fontWeight={600}
        />
      )}
      {!displayOtherLinksOnly && (
        <CategoryButton
          sizeVariant={sizeVariant}
          label="Nuevos"
          title="Nuevos"
          color="secondary"
          as={Link}
          to="/c/nuevos"
          onClick={onClick}
          classes={classes}
          fontWeight={600}
        />
      )}
      {showPromoButtonFirst && (
        <CategoryButton
          sizeVariant={sizeVariant}
          label="Promofit"
          title="Promofit"
          color="secondary"
          as={Link}
          to="/c/promofit"
          onClick={onClick}
          classes={{
            root: css`
              ${tw`(text-primaryV2-50 gap-xxs p-0 hover:(bg-transparent text-primaryV2-40))!`}
              ${classes?.root}
            `,
          }}
          endIcon={<IconLabelTag width={24} height={24} />}
          fontWeight={600}
        />
      )}
      {otherLinks}
      {!displayOtherLinksOnly && !showPromoButtonFirst && (
        <CategoryButton
          sizeVariant={sizeVariant}
          label="Promofit"
          title="Promofit"
          color="secondary"
          as={Link}
          to="/c/promofit"
          onClick={onClick}
          classes={{
            root: css`
              ${tw`(text-primaryV2-50 p-0 gap-xxs hover:(bg-transparent underline))!`}
            `,
          }}
          endIcon={<IconLabelTag width={24} height={24} />}
          fontWeight={600}
        />
      )}
    </>
  );
};

export default HeaderPrimaryLinks;
