import Paragraph from "@/components/Atoms/Paragraph";
import {
  StyledActionsWrapper,
  StyledBodyInfoModal,
  StyledNotificationBox,
  StyledPrimaryActionsWrapper,
  StyledWrapperContent,
  StyledWrapperFooterModal,
  StyledWrapperRight,
} from "../AuthStepsModal/styles";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import Heading from "@/components/Atoms/Heading";
import { Controller, type UseFormMethods } from "react-hook-form";
import { type AuthStep } from "@/components/Navbar/AuthModal/types";
import { PhoneInput } from "@/components/Molecules/PhoneInput";
import Button from "@/components/Atoms/Button";
import IconAuth from "@/components/Atoms/IconAuth";
import Notification from "@/components/Atoms/Notification";
import StepOrBackHeader from "@/components/Atoms/StepOrBackHeader";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface SendPhoneCodeProps {
  onClose: () => void;
  onSubmit: () => Promise<void>;
  controller: Pick<
    UseFormMethods,
    "control" | "errors" | "clearErrors" | "formState" | "register" | "getValues"
  >;
  setStep: React.Dispatch<React.SetStateAction<AuthStep>>;
  type: "signUp" | "recovery";
  loading: boolean;
  fetchingError: string[] | string;
  cleanErrors: () => void;
  isMobile?: boolean;
}

const SendPhoneCode = ({
  onClose,
  onSubmit,
  controller: { control, clearErrors, errors, formState, register, getValues },
  setStep,
  type,
  loading,
  fetchingError,
  cleanErrors: cleanErrorsFetch,
  isMobile,
}: SendPhoneCodeProps) => {
  const isLarge = useMediaQuery("(min-width: {large})");

  const submit = async (channel: "sms" | "whatsapp") => {
    void onSubmit({ channel });
  };
  const content = {
    recovery: {
      title: "¿Olvidaste tu contraseña?",
      paragraph: "Ingresa el número de contacto asociado a tu cuenta y te enviaremos el código de seguridad.",
      icon: <IconAuth type="exit" />,
    },
    signUp: {
      title: "Crear cuenta",
      paragraph: "Enviaremos un código a tu número de contacto para proteger tu cuenta.",
      icon: <IconAuth type="user" />,
    },
  };

  return (
    <StyledWrapperRight $hMax={type !== "signUp"}>
      <StyledWrapperContent>
        {!isMobile && <IconClose tw="h-ml w-ml justify-self-end cursor-pointer" onClick={onClose} />}
        {isMobile && (
          <div tw="-mt-s -mx-m">
            <StepOrBackHeader onBack={() => setStep("login")} />
          </div>
        )}
        {content[type].icon}
        <StyledBodyInfoModal>
          <Heading type="h2">{content[type].title}</Heading>
          <Paragraph sizeVariant="md">{content[type].paragraph}</Paragraph>
        </StyledBodyInfoModal>
        <Controller
          render={({ onChange, onBlur }): React.ReactElement => {
            function handleOnChange(value: string): void {
              clearErrors("phoneNumber");
              onChange(value);
              cleanErrorsFetch();
            }

            return (
              <PhoneInput
                onChange={handleOnChange}
                label="Ingresa tu número de contacto"
                placeholder="Ingresa tu número"
                size={isLarge ? "medium" : "medium"}
                withReferenceWidth
              />
            );
          }}
          name="phoneNumber"
          control={control}
          rules={{
            validate: (value: string): boolean => {
              const regex =
                /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([1-9]1[01-9]|[1-9][01-8]1|[1-9][01-8][01-9])\s*)|([1-9]1[01-9]|[1-9][01-8]1|[1-9][01-8][01-9]))\s*(?:[.-]\s*)?)([1-9]1[01-9]|[1-9][01-9]1|[1-9][01-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
              return regex.test(value);
            },
          }}
        />
      </StyledWrapperContent>

      <StyledActionsWrapper>
        <StyledPrimaryActionsWrapper>
          <Button
            color="secondary"
            sizeVariant="lg"
            type="button"
            onClick={(): any => submit("whatsapp")}
            disabled={!formState.isValid || loading}
            fullWidth
          >
            Enviar a Whatsapp
          </Button>
          <Button
            color="secondary"
            sizeVariant="sm"
            variant="text"
            type="button"
            onClick={async () => await submit("sms")}
            disabled={!formState.isValid || loading}
            fullWidth
          >
            Enviar por SMS
          </Button>
        </StyledPrimaryActionsWrapper>
        <StyledNotificationBox visible={!!fetchingError && fetchingError?.length > 0}>
          <Notification type="error" messages={fetchingError} hFull />
        </StyledNotificationBox>
        {type === "signUp" && (
          <StyledWrapperFooterModal>
            <Paragraph sizeVariant="sm"> ¿Ya tienes cuenta con nosotros?</Paragraph>

            <Button
              color="secondary"
              sizeVariant="sm"
              variant="text"
              type="button"
              onClick={() => setStep("login")}
            >
              Iniciar sesión
            </Button>
          </StyledWrapperFooterModal>
        )}
      </StyledActionsWrapper>
    </StyledWrapperRight>
  );
};
export default SendPhoneCode;
