import tw, { styled } from "twin.macro";
import Label from "@/components/Atoms/Label";
import { type TColor } from "@/types/common";

interface StyledToggleRootProps {
  $checked?: boolean;
  $color: TColor;
  $disabled?: boolean;
}

interface StyledCircleToogleProps {
  $checked?: boolean;
  $disabled?: boolean;
}

const styles = {
  root: {
    base: tw`cursor-pointer flex items-center w-l h-[1.125rem] p-[0.188rem] bg-neutral-90 rounded-full transition-["color","transform"] ease-in duration-300`,
    cheked: {
      color: {
        primary: tw`bg-primaryV2-50 `,
        secondary: tw`bg-secondary-60 `,
        neutral: tw`bg-neutral-10 `,
      },
    },
    disabled: tw`cursor-not-allowed  bg-neutral-90 `,
  },
  input: tw`absolute opacity-0 w-0 h-0`,
  circle: {
    base: tw`w-[0.75rem] h-[0.75rem] rounded-full`,
    cheked: {
      default: tw`translate-x-0 bg-neutral-10`,
      active: tw`translate-x-[0.813rem] bg-neutral-100`,
    },
    disabled: tw`bg-neutral-80 translate-x-0`,
  },
};

// root
export const StyledToggleRoot = styled(Label)<StyledToggleRootProps>`
  ${styles.root.base}
  ${({ $checked, $color, $disabled }) => {
    if ($disabled) return styles.root.disabled;
    return $checked ? styles.root.cheked.color[$color] : "";
  }}
`;

// input
export const StyledInputToogle = styled.input`
  ${styles.input}
`;

// circle
export const StyledCircleToogle = styled.div<StyledCircleToogleProps>`
  ${styles.circle.base}
  ${({ $checked, $disabled }) => {
    if ($disabled) return styles.circle.disabled;

    return $checked ? styles.circle.cheked.active : styles.circle.cheked.default;
  }}
`;
