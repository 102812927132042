import { useState, useEffect } from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { useQueryCache } from "react-query";
import Ciity from "@atoms/Ciity";
import CategoryButton from "@atoms/CategoryButton";
import HeaderMenu from "@/components/Molecules/HeaderMenu";
import InputSearch from "@molecules/InputSearch";
import ProfileButton from "@molecules/ProfileButton";
import NotificationsHeader from "@organisms/NotificationsHeader";
import useSessionContext from "@/hooks/useSesionContext";
import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import { cartStoresActions } from "@/store/cartStore";
import useModalAuth from "@/hooks/useModalAuth";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { userAction } from "@/store/user";
import ShoppingCart from "../ShoppingCart";
import useAddressModal from "@/hooks/useAddressModal";
import {
  StyledLogoFithubPrimary,
  StyledRootHeader,
  StyledContainer,
  StyledHeaderHead,
  StyledHeaderBody,
  StyledHeaderHeadRight,
  StyledHeaderHeadLeft,
  StyledTopNotificationRoot,
  StyledHeaderButton,
  StyledHeaderButtonLeft,
  StyledHeaderButtonRight,
} from "./styles";
import { InView } from "react-intersection-observer";
import ListPopover from "@/components/Molecules/ListPopover";
import PrimaryButtonHeader from "@/components/Molecules/PrimaryButtonHeader";
import BottomNotificationHeader from "@components/Organisms/BottomNotificationHeader";
import IconOrderRound from "@/components/Atoms/IconOrderRound";
import CategoryList from "@/components/Molecules/CategoryList";
import { useHasScrolled } from "@/hooks/useHasScrolled";
import useHeaderData from "@/hooks/useHeaderData";
import HeaderPrimaryLinks from "@/components/Molecules/HeaderPrimaryLinks";
import useMounted from "@/hooks/useMounted";

const categoryButtonStyles = {
  root: tw`(rounded-s medium:(p-0) hover:(bg-transparent text-secondary-60 underline))!`,
};

export const newsAndPromotions = [
  {
    name: "Nuestra marca",
    slug: "nuestra-marca",
  },
  {
    name: "Nuevos",
    slug: "nuevos",
  },
  {
    name: "Promofit",
    slug: "promofit",
  },
];

const Header = () => {
  // Stores
  const location = useAppSelector((state) => state.cart.location);
  const cartState = useAppSelector((state) => state.cart);
  const user = useAppSelector((state) => state.user);

  // States
  const [openMenuSearch, setOpenMenuSearch] = useState(false);
  const [inView, setInView] = useState(false);
  // const [isMountedComponent, setIsMountedComponent] = useState(false);

  // Hooks
  const displayRedeemYourGift = useMediaQuery("( min-width: 716px )");
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const isLarge = useMediaQuery("( min-width: {large} )");
  const dispatch = useAppDispatch();
  const isMountedComponent = useMounted(500);

  const { setUserToken } = useSessionContext();
  const queryCache = useQueryCache();

  const { handleOpenModalAuth } = useModalAuth();
  const { isAthenticated } = useSessionContext();

  const hasScrolled = useHasScrolled();

  const { isOpen: isAddressModalOpen, toggleAddressModal: setIsAddressModalOpen } = useAddressModal();

  const { hasPendingOrders, queryAvailableStores, getCategoriesHeader, getHubs, dataMenuCategories } =
    useHeaderData({
      location,
      cartState,
      user,
    });

  // Constants
  const { status, data: dataAvailableStores, refetch, error } = queryAvailableStores;

  const isScrolled = !inView && hasScrolled;

  const isAddressActive = !!location?.lat && !!location?.lng && (!!location?.city || !!location?.address);

  // Functions

  const getUserLabel = (noFullName = false) => {
    if (user?.firstName && user?.lastName && !noFullName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user?.firstName) {
      return user.firstName;
    }
    return user?.email ?? "";
  };

  // useEffects

  useEffect(() => {
    if (status === "success" && dataAvailableStores) {
      dispatch(
        cartStoresActions.setAvailableStores({
          availableStores: dataAvailableStores,
          validateAt: new Date().getTime(),
        })
      );

      if (!cartState.storeId) {
        dispatch(cartStoresActions.setStoreId(dataAvailableStores?.stores?.[0]?.id));
      } else if (cartState.storeId !== dataAvailableStores?.stores?.[0]?.id) {
        setIsAddressModalOpen(true);
      }
    }

    if (status === "error") {
      if (
        (error as any)?.response &&
        ((error as any).response.status === 401 || (error as any).response.status === 403)
      ) {
        setUserToken(null);
        dispatch(userAction.emptyUser());
        dispatch(cartStoresActions.emptyCart({}));
        dispatch(cartStoresActions.emptyAvailability());
      }

      void refetch();
      dispatch(cartStoresActions.emptyLocation());
    }
  }, [status, dataAvailableStores, cartState.storeId, error]);

  useEffect(() => {
    if (cartState.storeId) {
      void getCategoriesHeader();
      void getHubs();
    }
  }, [cartState.storeId]);

  useEffect(() => {
    if (!isAddressActive) {
      dispatch(cartStoresActions.setInitialLocation());
      void queryCache.refetchQueries("stores");
    }
  }, [isAddressActive]);

  const InputComponent = (
    <InputSearch
      itemHubs={dataMenuCategories.hubs}
      itemCategories={dataMenuCategories.categories.map((item) => ({
        slug: `/c/${item.slug}`,
        name: item.name,
      }))}
      openMenuSearchExternal={openMenuSearch}
      handleOpenMenuSearchExternal={setOpenMenuSearch}
      isScrolled={isScrolled}
    />
  );

  return (
    <>
      {/* Top Notification */}
      <StyledTopNotificationRoot $isScrolled={isScrolled}>
        <NotificationsHeader />
      </StyledTopNotificationRoot>

      {/* Header Section Top */}
      <StyledRootHeader $isScrolled={isScrolled}>
        <StyledContainer tw="large:(px-0)">
          <StyledHeaderHead>
            <StyledHeaderHeadLeft $isScrolled={isScrolled}>
              {(!isMedium || (isMedium && isScrolled) || (isLarge && isScrolled)) && (
                <HeaderMenu isScrolled={isScrolled} />
              )}

              {!isScrolled && (
                <Link to="/" title="Fithub">
                  <StyledLogoFithubPrimary />
                </Link>
              )}

              {isLarge && !isScrolled && InputComponent}

              {!inView && isMountedComponent && (
                <HeaderPrimaryLinks
                  sizeVariant="xs"
                  displayOtherLinksOnly={!isLarge}
                  showPromoButtonFirst={!isLarge && isMedium}
                  classes={categoryButtonStyles}
                  otherLinks={
                    <CategoryList
                      data={{
                        additionalMenuCategories: !isLarge
                          ? {
                              title: "Novedades y promociones",
                              data: newsAndPromotions,
                              basePath: "/c/",
                            }
                          : undefined,
                        hub: !isMedium
                          ? {
                              title: "Hubs",
                              basePath: "/h/",
                              data: dataMenuCategories.hubs,
                            }
                          : undefined,
                        category: {
                          title: "Todas las categorías",
                          basePath: "/c/",
                          data: dataMenuCategories.categories,
                        },
                      }}
                      classes={categoryButtonStyles.root}
                      placement="bottom-start"
                      notVisible={inView}
                    />
                  }
                />
              )}
            </StyledHeaderHeadLeft>

            <StyledHeaderHeadRight $isScrolled={isScrolled}>
              {isScrolled && isMedium && InputComponent}

              {/* Primary buttons Avatar or ingresar, Favoritos,Tus pedidos */}
              <>
                {((isAthenticated() && !isScrolled) || !isAthenticated()) && (
                  <ProfileButton
                    label={getUserLabel()}
                    firstName={getUserLabel(true)}
                    onClickLogin={() => handleOpenModalAuth({ initialStep: "login" })}
                  />
                )}
                {/* Esperar que se implemente la página de favoritos */}

                {/* {!isScrolled && isAthenticated() && (
                  <PrimaryButtonHeader title="Favoritos" icon={<IconHeartOutline />}>
                    {isMedium ? "Favoritos" : undefined}
                  </PrimaryButtonHeader>
                )} */}

                {isAthenticated() && (
                  <PrimaryButtonHeader
                    title="Tus pedidos"
                    icon={
                      <IconOrderRound
                        isScrolled={isScrolled}
                        isNotificationBadgeVisible={hasPendingOrders}
                        isRound={!isMedium || isScrolled}
                      />
                    }
                    to="/orders"
                  >
                    {isMedium && !isScrolled ? "Tus pedidos" : undefined}
                  </PrimaryButtonHeader>
                )}
              </>

              <ShoppingCart isScrolled={isScrolled} />
            </StyledHeaderHeadRight>
          </StyledHeaderHead>

          {(!isMedium || (isMedium && !isLarge && !isScrolled)) && (
            <StyledHeaderBody>{InputComponent}</StyledHeaderBody>
          )}
        </StyledContainer>
      </StyledRootHeader>

      {/* Header Section Bottom */}

      <InView onChange={(inView) => setInView(inView)}>
        <StyledHeaderButton>
          <StyledHeaderButtonLeft $isSmall={!isMedium}>
            <Ciity open={isAddressModalOpen} handleChangeOpen={setIsAddressModalOpen} removeBoxStyle />
            <HeaderPrimaryLinks
              sizeVariant={"xs"}
              displayOtherLinksOnly={!isLarge}
              showPromoButtonFirst={!isLarge && isMedium}
              classes={categoryButtonStyles}
              otherLinks={
                <>
                  <CategoryList
                    data={{
                      additionalMenuCategories: !isLarge
                        ? {
                            title: "Novedades y promociones",
                            data: newsAndPromotions,
                            basePath: "/c/",
                          }
                        : undefined,
                      hub: !isMedium
                        ? {
                            title: "Hubs",
                            basePath: "/h/",
                            data: dataMenuCategories.hubs,
                          }
                        : undefined,
                      category: {
                        title: "Todas las categorías",
                        basePath: "/c/",
                        data: dataMenuCategories.categories,
                      },
                    }}
                    classes={categoryButtonStyles.root}
                    placement="bottom-start"
                    notVisible={inView}
                  />
                  {isLarge && (
                    <ListPopover
                      data={dataMenuCategories.hubs}
                      classes={categoryButtonStyles.root}
                      label="Hubs"
                      title="Hubs"
                      basePath="/h/"
                      notVisible={inView}
                    />
                  )}
                </>
              }
            />
          </StyledHeaderButtonLeft>

          {isMedium && (
            <StyledHeaderButtonRight>
              {/* Esperar implemnetar la pagina de comunidad */}
              {/* <CategoryButton
                label="Comunidad"
                color="secondary"
                as={Link}
                to={"https://blog.fithub.com.co"}
                title={`Comunidad de Fithub`}
                target="_blank"
                rel="noopener noreferrer"
                sizeVariant="xs"
                classes={categoryButtonStyles}
                fontWeight={600}
              /> */}

              {displayRedeemYourGift && (
                <CategoryButton
                  label="Canjea tu regalo"
                  color="secondary"
                  as={Link}
                  title="Canjea tu regalo"
                  to="/gift-cards/redeem"
                  sizeVariant="xs"
                  classes={categoryButtonStyles}
                  fontWeight={600}
                />
              )}
              <CategoryButton
                label="Blog"
                color="secondary"
                as={Link}
                to={"https://blog.fithub.com.co"}
                title={`Blog de Fithub`}
                target="_blank"
                rel="noopener noreferrer"
                sizeVariant="xs"
                classes={categoryButtonStyles}
                fontWeight={600}
              />
            </StyledHeaderButtonRight>
          )}
        </StyledHeaderButton>
      </InView>

      {/* Bottom Notification */}

      <BottomNotificationHeader />
    </>
  );
};

export default Header;
