import type { CSSProperties, ReactNode } from "react";
import Heading from "@atoms/Heading";
import Paragraph from "@atoms/Paragraph";
import Button, { type IButton } from "@atoms/Button";
import NotificationAlert, { type ETypeNotification } from "@atoms/NotificationAlert";
import IconClose from "@iconsV2/Edit/close.svg?react";
import { StyledWrapperButtonClose, StyledWrapperModalAlert } from "./styles";

export interface ModalAlertProps {
  title?: string;
  description?: ReactNode;
  icon?: ReactNode;
  cancel?: {
    label: string;
    action: () => void;
    color?: IButton["color"];
    variant?: IButton["variant"];
  };
  confirm?: {
    label: string;
    action: () => void;
    color?: IButton["color"];
    variant?: IButton["variant"];
  };
  notification?: {
    title: ReactNode;
    type: ETypeNotification;
  };
  actionIconClose?: () => void;
  maxWidth?: CSSProperties["maxWidth"];
}

const ModalAlert = ({
  title,
  description,
  icon,
  cancel,
  confirm,
  notification,
  maxWidth,
  actionIconClose,
}: ModalAlertProps) => {
  return (
    <StyledWrapperModalAlert $maxWidth={maxWidth}>
      {actionIconClose && (
        <StyledWrapperButtonClose>
          <button onClick={actionIconClose}>
            <IconClose tw="h-[1.5rem] w-[1.5rem]" />
          </button>
        </StyledWrapperButtonClose>
      )}

      {icon}

      {title && (
        <Heading type="h2" tw="max-w-[90%]">
          {title}
        </Heading>
      )}

      {notification && (
        <NotificationAlert title={notification.title} variant="default" type={notification.type} />
      )}

      {description && <Paragraph sizeVariant="lg">{description}</Paragraph>}

      {(Boolean(cancel) || Boolean(confirm)) && (
        <>
          {confirm && (
            <Button
              sizeVariant="lg"
              color={confirm.color ?? "secondary"}
              fullWidth
              variant={confirm.variant ?? "filled"}
              onClick={confirm?.action}
            >
              {confirm.label}
            </Button>
          )}

          {cancel && (
            <Button
              sizeVariant="lg"
              color={cancel.color ?? "secondary"}
              fullWidth
              variant={cancel.variant ?? "outlined"}
              onClick={cancel?.action}
            >
              {cancel.label}
            </Button>
          )}
        </>
      )}
    </StyledWrapperModalAlert>
  );
};

export default ModalAlert;
