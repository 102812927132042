import tw, { styled, css, theme } from "twin.macro";
import { type CSSProperties } from "styled-components";
import type { ITypographyStyledCommon, THeading } from "@/types/common";

interface IStyledWrapperHeading extends Omit<ITypographyStyledCommon, "$sizeVariant"> {
  $fontWeight?: CSSProperties["fontWeight"];
  $textAlign?: CSSProperties["textAlign"];
  $type: THeading;
  $variant?: THeading;
  $disabled?: boolean;
}

const headingStyles = {
  h1: {
    small: tw`text-[1.5rem] tracking-[0.0225rem] leading-[2.277rem]`,
    medium: tw`text-[2.625rem] leading-[2.6565rem] tracking-[0.0525rem]`,
    large: tw`text-[3rem] leading-[3.036rem] tracking-[0.06rem]`,
  },
  h2: {
    small: tw`text-[1.625rem] tracking-[0.01625rem]`,
    medium: tw`text-[1.875rem] tracking-[0.01875rem]`,
    large: tw`text-[2.125rem] tracking-[0.02125rem]`,
  },
  h3: {
    small: tw`text-[1.375rem] tracking-[0.01375rem]`,
    medium: tw`text-[1.5rem] tracking-[0.015rem]`,
    large: tw`text-[1.75rem] tracking-[0.0175rem]`,
  },
  h4: {
    small: tw`text-[1.125rem] tracking-[0.01125rem]`,
    medium: tw`text-[1.25rem] tracking-[0.0125rem]`,
    large: tw`text-[1.5rem] tracking-[0.015rem]`,
  },
  h5: {
    small: tw`text-[1rem] tracking-[0.00875rem]`,
    medium: tw`text-[1.125rem] tracking-[0.01rem]`,
    large: tw`text-[1.25rem] tracking-[0.01125rem]`,
  },
  h6: {
    small: tw`text-[0.875rem] tracking-[1%]`,
    medium: tw`text-[1rem] tracking-[1%]`,
    large: tw`text-[1.125rem] tracking-[1%]`,
  },
};

export const StyledWrapperHeading = styled.h2<IStyledWrapperHeading>`
  ${tw`font-primaryV2 font-normal transition-all ease-in-out duration-300 text-neutral-10 leading-[normal]`}

  & > * {
    ${tw`font-primaryV2 leading-[inherit] font-normal`};
  }

  ${({ $type = "h2", $variant, $size }) => {
    const headingType = headingStyles[$variant ?? $type];

    if ($size) {
      return headingType[$size];
    }

    return css`
      ${headingType.small}

      @media (min-width: ${theme`screens.medium`}) {
        ${headingType.medium}
      }

      @media (min-width: ${theme`screens.large`}) {
        ${headingType.large}
      }
    `;
  }}

  ${({ $variant, $type }) => (["h6", "h5"].includes($variant ?? $type) ? tw`font-semibold` : tw`font-bold`)}

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}

    ${({ $fontWeight }) => {
    if ($fontWeight) {
      return css`
        font-weight: ${$fontWeight};
      `;
    }
  }}

    ${({ $textAlign }) => {
    if ($textAlign) {
      return css`
        text-align: ${$textAlign};
      `;
    }
  }}

  ${({ $disabled }) => {
    if ($disabled) {
      return tw`text-neutral-60`;
    }
  }};
`;
