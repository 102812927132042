import { genericsAction } from "@/store/generics";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import { useNavigate } from "react-router-dom";
import { getAvaliableStores } from "@/services/stores";
import { cartStoresActions } from "@/store/cartStore";
import { popupsRemunderAction } from "@/store/PopUpsReminderSlice";
import { ECheckoutURL } from "./useCheckout/types";

export enum CALLBACK_MODAL_ADDRESS {
  NAVIGATE_TO_CHECKOUT = "NAVIGATE_TO_CHECKOUT",
}

const useModalAddress = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.generics.modalAddress.openModalAddress);
  const callbackIdentifier = useAppSelector((state) => state.generics.modalAddress.callbackIdentifier);
  const showLocationModal = useAppSelector((state) => state.popUpsReminder.showLocationModal);

  const toggleAddressModal = (isOpen: boolean, callbackIdentifier?: CALLBACK_MODAL_ADDRESS) => {
    dispatch(genericsAction.setOpenModalAddress({ open: isOpen, callbackIdentifier }));
  };

  const handleCallback = () => {
    if (callbackIdentifier === CALLBACK_MODAL_ADDRESS.NAVIGATE_TO_CHECKOUT) {
      navigate(ECheckoutURL.REVIEW_PAGE);
    }

    dispatch(genericsAction.setCallbackAddressIdentifier(undefined));
  };

  const handleChangeLocation = async (region: CityType | Omit<LocationType, "test" | "isPickUp"> | null) => {
    if (!region) {
      return;
    }

    const store = await getAvaliableStores("", Number(region?.lat), Number(region?.lng));

    if (!store) {
      throw new Error("No hay tiendas disponibles");
    }

    if (String(region?.lat) !== "" && String(region?.lng) !== "") {
      dispatch(
        cartStoresActions.setLocation({
          address: "name" in region ? region?.name : region?.address,
          lat: Number(region?.lat),
          lng: Number(region?.lng),
          test: "name" in region,
          city: "city" in region ? region?.city?.toLowerCase() : store.city.toLowerCase(),
          ...("name" in region ? {} : { extra: region?.extra, id: region?.id, alias: region?.alias }),
          isPickUp: false,
        })
      );
    }

    if (showLocationModal) {
      dispatch(
        popupsRemunderAction.setPopUpsReminder({
          showLocationModal: false,
          showAddressReminder: false,
        })
      );
    }
    dispatch(
      cartStoresActions.setAvailableStores({
        availableStores: store,
        validateAt: new Date().getTime(),
      })
    );

    dispatch(cartStoresActions.setStoreId(store.stores[0].id));
  };

  return {
    isOpen,
    toggleAddressModal,
    handleCallback,
    handleChangeLocation,
  };
};

export default useModalAddress;
