import { useQueries } from "@tanstack/react-query";
import { OrdersEnum } from "@/recourses/queryKeys";
import { getListPedingOrders } from "@/services/orders/orders";
import { getAvaliableStores } from "@/services/stores";

import { useState } from "react";
import { type QueryResultCategoriesHubs, type CategoryNodeHeader } from "@/components/Organisms/Header/types";
import { type IHub } from "@/types/hubs";
import { useLazyQuery } from "@apollo/client";
import { GET_CATEGORIES_HEADER } from "@/graphql/categories";
import { GET_HUBS } from "@/graphql/hubs";
import { type User } from "@/store/user/index";

interface IUseHeaderDataProps {
  location: LocationType;
  cartState: CartStoreAttr;
  user: User | null;
}
const useHeaderData = ({ ...props }: IUseHeaderDataProps) => {
  const { cartState, location, user } = props;

  const [dataMenuCategories, setDataMenuCategories] = useState<{
    categories: CategoryNodeHeader[];
    hubs: IHub[];
  }>({
    categories: [],
    hubs: [],
  });

  const [getCategoriesHeader] = useLazyQuery<QueryResultCategoriesHubs>(GET_CATEGORIES_HEADER, {
    variables: {
      storeId: String(cartState.storeId),
    },
    onCompleted(data) {
      const ignoreFiletr = ["promofit", "nuevos", "nuestra-marca"];
      const { categories } = data;
      const formatedCategories = categories.edges
        .filter((item) => !ignoreFiletr.includes(item.node.slug))
        .map((item) => ({
          name: item.node.name,
          slug: item.node.slug,
        }));

      setDataMenuCategories((prev) => ({
        ...prev,
        categories: formatedCategories,
      }));
    },
  });

  const [getHubs] = useLazyQuery(GET_HUBS, {
    variables: {
      storeId: String(cartState.storeId),
    },
    onCompleted(data) {
      const { hubs } = data;

      const formatedHubs = hubs.edges.map((item) => ({
        ...item.node,
      }));

      setDataMenuCategories((prev) => ({
        ...prev,
        hubs: formatedHubs,
      }));
    },
  });

  const querys = useQueries({
    queries: [
      {
        queryKey: [OrdersEnum.pendingOrders, user?.id],
        queryFn: async () => await getListPedingOrders(),
        enabled: Boolean(user),
        refetchOnWindowFocus: true,
      },

      {
        queryKey: ["availableStores", location?.lat, location?.lng],
        queryFn: async () => await getAvaliableStores("", location?.lat, location?.lng),
        enabled: !!location?.lat && !!location?.lng,
      },
    ],
  });
  const [queryPendingOrders, queryAvailableStores] = querys;
  const hasPendingOrders = Boolean(queryPendingOrders.data?.data?.count);

  return {
    hasPendingOrders,
    queryAvailableStores,
    getCategoriesHeader,
    getHubs,
    dataMenuCategories,
    setDataMenuCategories,
  };
};

export default useHeaderData;
