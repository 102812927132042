import { forwardRef, type ReactNode, type InputHTMLAttributes } from "react";
import type { RuleSet } from "styled-components";
import type { TwStyle } from "twin.macro";
import type { TSize } from "@/types/common";

import IconSuccess from "@iconsV2/Controllers/radio-checked-toned.svg?react";
import IconError from "@iconsV2/Controllers/radio-excluded-toned.svg?react";

import { StyledIcon, StyledSuccessErrorIcon, StyledWrapperTextField } from "./styles";

export interface ITextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  size?: TSize;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  isLoading?: boolean;
  success?: boolean;
  errors?: boolean;
  classes?: {
    root?: RuleSet<object> | TwStyle;
    input?: RuleSet<object> | TwStyle;
  };
  withIconSuccessError?: boolean;
}

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(
  (
    {
      size,
      startIcon,
      endIcon,
      fullWidth = true,
      isLoading = false,
      success = false,
      errors = false,
      withIconSuccessError = true,
      classes,
      ...props
    },
    ref
  ) => {
    const renderStartIcon = () => {
      if ((errors || success) && withIconSuccessError) {
        return (
          <StyledSuccessErrorIcon $errors={errors} $success={success} $size={size}>
            {errors ? <IconError /> : <IconSuccess />}
          </StyledSuccessErrorIcon>
        );
      }

      if (startIcon) {
        return <StyledIcon $size={size}>{startIcon}</StyledIcon>;
      }
    };

    return (
      <StyledWrapperTextField
        $fullWidth={fullWidth}
        $hasValue={!!props.value}
        disabled={props.disabled}
        $isLoading={isLoading}
        $success={success}
        $errors={errors}
        $size={size}
        $classes={classes}
        onClick={props?.onClick}
      >
        {renderStartIcon()}
        <input ref={ref} {...props} readOnly={props?.readOnly ?? isLoading} />
        {endIcon && <StyledIcon $size={size}>{endIcon}</StyledIcon>}
      </StyledWrapperTextField>
    );
  }
);

export default TextField;
