import { useEffect, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CheckoutEnum } from "@/recourses/queryKeys";
import { cartStoresActions } from "@/store/cartStore";
import { getCartAvailableProducts } from "@/services/checkout";
import { type IGetAvailableProductsResponse } from "@/services/checkout/type";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import useCheckoutSummary from "./useCheckoutSummary";

interface IUseKeepCartFresh {
  onValidate?: (data: IGetAvailableProductsResponse) => void;
  omit?: boolean;
  items?: ProcessedItems[];
  revalidateWhen?: Array<boolean | string | number | null | undefined>;
}

const useKeepCartFresh = ({
  onValidate,
  omit = false,
  items: argsItems,
  revalidateWhen,
}: IUseKeepCartFresh = {}) => {
  const dispatch = useAppDispatch();
  const storeId = useAppSelector((state) => state.cart.storeId);
  const { processedItems: items } = useCheckoutSummary();

  const itemsRef = useRef(items);

  let localItems = itemsRef.current;
  if (argsItems) {
    localItems = argsItems;
  }

  // Get cart available products
  const { data: cartAvailables } = useQuery({
    queryKey: [
      CheckoutEnum.getCartAvailableProducts,
      +storeId!,
      JSON.stringify(itemsRef.current),
      argsItems,
      ...(revalidateWhen ?? []),
    ],
    queryFn: async () => {
      const productsId = localItems.map((product) => String(product.product.sku)).join(",");
      return await getCartAvailableProducts(Number(storeId), productsId);
    },
    enabled: localItems.length > 0 && !!storeId && !omit,
    gcTime: 1000 * 60 * 1, // 1 minutes
  });

  const verifyAvailableProductsMutation = useMutation({
    mutationKey: [CheckoutEnum.getCartAvailableProducts],
    mutationFn: async (storeID: number) => {
      const productsId = localItems.map((product) => String(product.product.sku)).join(",");
      return await getCartAvailableProducts(Number(storeID), productsId);
    },
    onSuccess: (data) => {
      if (data) {
        handleChangeData(data);
      }
    },
  });

  const handleChangeData = (data: IGetAvailableProductsResponse | undefined) => {
    if (!data) return;

    localItems.forEach((item) => {
      const product = data?.payload.find((product) => String(product.sku) === String(item.product.sku));

      if (!product) {
        dispatch(
          cartStoresActions.changeQuatityAvailable({
            productId: Number(item?.product.id),
            maxQtyToOrder: 0,
            isAvailable: false,
          })
        );
        return;
      }

      dispatch(
        cartStoresActions.changeQuatityAvailable({
          productId: Number(item?.product.id),
          maxQtyToOrder: product?.quantity_available ?? 0,
          isAvailable: product?.is_available ?? false,
          price: product?.price ?? { gross: 0, currency: "COP" },
          preceUndiscounted: product?.price_undiscounted ?? { gross: 0, currency: "COP" },
          onSale: product?.on_sale ?? false,
          stepQty: product?.step_qty ?? 1,
          measureTypeDisplayShort: product?.measure_type_display_short ?? null,
        })
      );
    });
  };

  useEffect(() => {
    if (cartAvailables) {
      handleChangeData(cartAvailables);
      onValidate?.(cartAvailables);
    }
  }, [JSON.stringify(cartAvailables?.payload)]);

  return {
    verifyAvailableProductsMutation,
  };
};

export default useKeepCartFresh;
