export enum ECheckoutURL {
  ADDRESS_PAGE = "/checkout/address",
  SHIPPING_PAGE = "/checkout/shipping",
  PAYMENT_PAGE = "/checkout/payment",
  PSE_PAYMENT_PAGE = "/checkout/pse-payment/:orderId",
  INVOICE_PAGE = "/checkout/invoice",
  SHOPPING_CART_PAGE = "/shopping-cart",
  REVIEW_PAGE = "/checkout/review",
  SUCCESS_PAGE = "/checkout/success",
}

export enum EDeliveryOptions {
  SELF = "self",
  CONCIERGE = "concierge",
  OTHER = "other",
}

export interface IService {
  id: string;
  name: string;
  appointmentType: string;
  date?: string;
  extraInfo: {
    id?: string;
    hours?: AvailableItinerary;
  };
}

export interface IStepFormData {
  [ECheckoutURL.ADDRESS_PAGE]: ((AddressAttr | PickUpStore) & { type?: string }) | null;
  [ECheckoutURL.SHIPPING_PAGE]: IService | null;
  [ECheckoutURL.PAYMENT_PAGE]: PaymentMethods | null;
  // [ECheckoutURL.PSE_PAYMENT_PAGE]: any;
  // [ECheckoutURL.INVOICE_PAGE]: any;
  // [ECheckoutURL.REVIEW_PAGE]: any;
  // [ECheckoutURL.SUCCESS_PAGE]: any;
}
