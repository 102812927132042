import { type FC, forwardRef, type PropsWithRef, type PropsWithChildren, type CSSProperties } from "react";
import { type RuleSet } from "styled-components";
import { theme, type TwStyle } from "twin.macro";
import { StyledWrapperCard, StyledZigZag } from "./styles";

export type ICard = PropsWithChildren<{
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  withShadow?: boolean;
  withZigzag?: boolean;
  borderRadius?: CSSProperties["borderRadius"];
  activeColor?: CSSProperties["borderColor"];
  active?: boolean;
  activeHover?: boolean;
  [key: string]: any;
}>;

const Card: FC<PropsWithRef<ICard>> = forwardRef<HTMLDivElement, ICard>(
  (
    {
      classes,
      children,
      withShadow = true,
      withBorder = true,
      withZigzag = false,
      borderRadius,
      active = false,
      activeHover = false,
      activeColor = theme`colors.primaryV2.50`,
      ...props
    },
    ref
  ) => {
    const CardComponent = (
      <StyledWrapperCard
        ref={ref}
        $classes={classes?.root}
        $withShadow={withShadow}
        $withBorder={withBorder}
        $withZigzag={withZigzag}
        $borderRadius={borderRadius}
        $active={active}
        $activeHover={activeHover}
        $activeColor={activeColor}
        {...props}
      >
        {children}
      </StyledWrapperCard>
    );

    if (withZigzag) {
      return (
        <div tw="relative w-full">
          {CardComponent}
          <StyledZigZag />
        </div>
      );
    }

    return CardComponent;
  }
);

export default Card;
