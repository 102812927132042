import tw, { css, styled } from "twin.macro";
import IconLoaderBold from "@/components/Icons/V2/Loader/loader.svg?react";
import IconLoaderLigth from "@/components/Icons/V2/Loader/loader-light.svg?react";
import { type CSSProperties } from "styled-components";

interface StyledLoaderProps {
  $width?: CSSProperties["width"];
  $height?: CSSProperties["height"];
  $animation?: "bounce" | "pulse1";
}
export const StyledLoaderCircleBold = styled(IconLoaderBold)<StyledLoaderProps>`
  ${tw`animate-spin`}
  ${({ $width, $height }) => css`
    width: ${$width};
    height: ${$height};
  `}
`;

export const StyledLoaderCircleLigth = styled(IconLoaderLigth)<StyledLoaderProps>`
  ${tw` animate-spin`}
  ${({ $width, $height }) => css`
    width: ${$width};
    height: ${$height};
  `}
`;

export const StyledLoaderDotRoot = styled.div<StyledLoaderProps>`
  ${tw`flex items-center justify-center space-x-2`}

  div {
    ${tw`rounded-full bg-primaryV2-50`}
    ${({ $animation = "bounce" }) => ($animation === "pulse1" ? tw`animate-pulse1` : tw`animate-bounceFast`)}
    ${({ $width, $height }) => css`
      width: ${$width};
      height: ${$height};
    `}
  }

  div:nth-child(1) {
    ${tw`[animation-delay:-0.3s]`}
  }
  div:nth-child(2) {
    ${({ $animation = "bounce" }) => $animation === "bounce" && tw`bg-primaryV2-60`}
    ${tw`[animation-delay:-0.15s]`}
  }
  div:nth-child(3) {
    ${({ $animation = "bounce" }) => $animation === "bounce" && tw`bg-primaryV2-70`}
  }
`;
