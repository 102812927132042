import tw, { styled } from "twin.macro";

const styles = {
  root: tw`flex flex-col gap-s medium:(gap-m)`,

  content: tw`relative flex gap-sm border transition-colors duration-300 rounded-s hover:(border-neutral-30 bg-neutral-99 ) medium:(rounded-m)`,

  icon: tw`cursor-pointer w-6 h-6`,

  iconLeftInput: tw`fill-neutral-50`,

  button: {
    root: tw`rounded-m bg-neutral hover:opacity-80 active:opacity-100`,
    svg: tw`w-7 h-7 large:(w-9 h-9)`,
  },

  inputContainer: tw`border-none bg-transparent px-m  py-s medium:(py-sm) large:(py-[10px])`,
};

export const StyledSearchBarRoot = styled.div`
  ${styles.root}
`;
export const StyledSearchBarContent = styled.div`
  ${styles.content}
  .icon {
    ${styles.icon}
  }
  .icon-left-input {
    ${styles.iconLeftInput}
  }

  Button {
    ${styles.button.root}
    svg {
      ${styles.iconLeftInput}
    }
  }
  div:nth-child(1) {
    ${styles.inputContainer}
  }
`;
