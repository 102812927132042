import { type AppState } from "@/store";

export const getStateReduxFromLocalStorage = (extraData?: string): AppState | null => {
  try {
    const storePersist = JSON.parse(extraData ?? localStorage.getItem("persist:root") ?? "{}") as Record<
      string,
      string
    >;

    return Object.keys(storePersist).reduce((state, key) => {
      return {
        ...state,
        [key]: JSON.parse(storePersist[key]),
      };
    }, {}) as AppState;
  } catch (error) {
    return null;
  }
};
