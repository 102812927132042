import Popover, { PopoverContent, PopoverTrigger } from "@/components/Atoms/Popover";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { type IHub } from "@/types/hubs";
import { type Placement } from "@floating-ui/react";
import { useEffect, useState } from "react";
import { StyledIconChevronDown, StyledWrapperPopoverButtons } from "./styles";
import CategoryButton from "@/components/Atoms/CategoryButton";
import tw, { css, type TwStyle } from "twin.macro";
import Button from "@/components/Atoms/Button";
import Heading from "@/components/Atoms/Heading";
import { Link, useLocation } from "react-router-dom";
import PopoverButton from "@/components/Atoms/PopoverButton";
import IconChevronLeft from "@iconsV2/Arrows/chevron-left-normal.svg?react";
import { type CategoryNodeHeader } from "@/components/Organisms/Header/types";

interface ListPopoverProps {
  placement?: Placement;
  data: IHub[] | CategoryNodeHeader[];
  classes?: TwStyle;
  label: string;
  title: string;
  basePath: string;
  notVisible?: boolean;
}
const ListPopover = ({
  placement = "bottom-start",
  data,
  classes: otherStyles,
  label,
  title,
  basePath,
  notVisible,
}: ListPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const { pathname } = useLocation();
  const params = pathname.split(basePath)[1];

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [notVisible]);
  return (
    <Popover
      open={isOpen}
      onOpenChange={isMedium ? setIsOpen : undefined}
      placement={placement}
      shift={false}
      flip={false}
      fullScreen={!isMedium}
    >
      <PopoverTrigger>
        <CategoryButton
          endIcon={<StyledIconChevronDown $open={isOpen} />}
          label={label}
          color="secondary"
          as="button"
          title={title}
          onClick={() => setIsOpen(!isOpen)}
          classes={{
            root: css`
              ${tw`gap-0!`}
              ${otherStyles}
            `,
          }}
          sizeVariant="xs"
          fontWeight={600}
        />
      </PopoverTrigger>

      {data && data.length >= 1 && (
        <PopoverContent classes={{ root: tw`gap-2 flex flex-col medium:(border border-neutral-90)` }}>
          {!isMedium && (
            <div tw="w-full px-2 py-2 flex bg-neutral-99">
              <Button
                sizeVariant="lg"
                onClick={() => setIsOpen(false)}
                color="secondary"
                variant="text"
                startIcon={<IconChevronLeft />}
                type="button"
              >
                Regresar
              </Button>
            </div>
          )}

          <StyledWrapperPopoverButtons>
            {!isMedium && (
              <>
                <Heading type="h6">{title}</Heading>
              </>
            )}

            {data?.map((item, index) => {
              const isActive = params === item.slug;

              return (
                <CategoryButton
                  className={`list-item ${isActive ? "list-item-active" : ""}`}
                  as={Link}
                  key={index}
                  label={item.name}
                  title={item.name}
                  to={`${basePath}${item.slug}`}
                  onClick={() => setIsOpen(false)}
                  color="secondary"
                  sizeVariant={isMedium ? "sm" : "md"}
                />
              );
            })}
          </StyledWrapperPopoverButtons>
        </PopoverContent>
      )}
    </Popover>
  );
};
export default ListPopover;
