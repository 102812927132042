import { useEffect, useState } from "react";
import { StyledCircleToogle, StyledInputToogle, StyledToggleRoot } from "./styles";
import { type TColor } from "@/types/common";

export interface ToggleProps {
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  color?: TColor;
  id: string;
}

const Toggle = ({
  onChange,
  checked: defaultChecked = false,
  disabled = false,
  color = "primary",
  id,
}: ToggleProps) => {
  const [isChecked, setIsChecked] = useState(Boolean(defaultChecked));

  useEffect(() => {
    if (defaultChecked !== isChecked && defaultChecked !== undefined) {
      setIsChecked(defaultChecked);
    }
  }, [defaultChecked]);

  const toggleSwitch = () => {
    if (disabled) return;
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onChange?.(newChecked);
  };

  return (
    <StyledToggleRoot htmlFor={id} sizeVariant="md" $checked={isChecked} $color={color} $disabled={disabled}>
      <StyledInputToogle
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={toggleSwitch}
        disabled={disabled}
        role="switch"
        aria-checked={isChecked}
        aria-disabled={disabled}
      />
      <StyledCircleToogle $checked={isChecked} $disabled={disabled} />
    </StyledToggleRoot>
  );
};

export default Toggle;
