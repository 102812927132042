import { forwardRef, type HTMLProps } from "react";
import tw, { css } from "twin.macro";
import { useMergeRefs, FloatingPortal } from "@floating-ui/react";

import Card, { type ICard } from "@atoms/Card";
import { useFloatingUIContext, FloatingUIProvider, type IFloatingUIProvider } from "@context/FloatingUI";
import { StyledWrapperArrowSvg, StyledWrapperTrigger } from "./styles";

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    // string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined
    const context = useFloatingUIContext();
    const ref = useMergeRefs([context.refs.setReference, propRef]);

    return (
      <StyledWrapperTrigger
        ref={ref}
        data-state={context.open ? "open" : "closed"}
        {...context.getReferenceProps(props)}
      >
        {children}
      </StyledWrapperTrigger>
    );
  }
);

export const TooltipContent = forwardRef<HTMLElement, HTMLProps<HTMLElement> & Pick<ICard, "classes">>(
  ({ style, children, classes, ...props }, propRef) => {
    const context = useFloatingUIContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) return null;
    return (
      <FloatingPortal>
        <Card
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...style,
            visibility: context.middlewareData.hide?.referenceHidden ? "hidden" : "visible",
          }}
          {...context.getFloatingProps(props)}
          classes={{
            root: css`
              ${tw`shadow-elevation-1 bg-neutral-100 z-50`}
              ${classes?.root ? classes.root : tw``}
            `,
          }}
        >
          {children}
          {context.arrow && <StyledWrapperArrowSvg ref={context.arrowRef} context={context.context} />}
        </Card>
      </FloatingPortal>
    );
  }
);

const Tooltip = ({ children, ...options }: Omit<IFloatingUIProvider, "role">) => {
  return (
    <FloatingUIProvider {...options} role="tooltip">
      {children}
    </FloatingUIProvider>
  );
};

export default Tooltip;
