import { API_URL_V1, API_URL_V2 } from "../constants";

export const shippingInfo = (storeId: string | number, lat: number, lng: number) => {
  return `${API_URL_V1}/stores/${storeId}/services/?lat=${lat}&lng=${lng}`;
};

export const quickStart = `${API_URL_V2}/orders/quick-start/`;

export const deliveryPrice = (id: string | number) => {
  return `${API_URL_V1}/services/${id}/price/`;
};
