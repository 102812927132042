import Popover, { PopoverContent, PopoverTrigger } from "@/components/Atoms/Popover";
import { StyledIconChevronDown } from "@/components/Molecules/PhoneInput/styles";
import { type ReactNode, useEffect, useState } from "react";
import { type IFloatingUIProvider } from "@/context/FloatingUI";
import tw, { css } from "twin.macro";
import PopoverButton from "@/components/Atoms/PopoverButton";
import { textNormalize } from "@/utils/textNormalize";
import Paragraph from "@/components/Atoms/Paragraph";
import { StyledSelectInput } from "./styles";
import { type ITextFieldProps } from "@/components/Atoms/TextField";
import Label from "@/components/Atoms/Label";

export type OptionSelect = { value: string; label?: string } | string;

export interface SelectProps {
  onChange: (value: OptionSelect | null) => void;
  inputProps?: Omit<ITextFieldProps, "value" | "onChange">;
  popoverProps?: Omit<IFloatingUIProvider, "role">;
  options: OptionSelect[];
  chosenIndicator?: ReactNode;
  clearOnEmpty?: boolean;
  label?: string;
  id?: string;
  defaultValue?: OptionSelect;
}

/**
 * Renders a select component with options and input field.
 *
 * @component
 * @example
 * ```tsx
 * <Select
 *   onChange={(option) => console.log(option)}
 *   options={['Option 1', 'Option 2', 'Option 3']}
 *   inputProps={{ label: 'Select an option' }}
 *   popoverProps={{ placement: 'bottom-start' }}
 * />
 * ```
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onChange - The callback function triggered when an option is selected.
 * @param {Array<string | OptionSelect>} props.options - The array of options to be displayed in the select component.
 * @param {Object} [props.inputProps] - Additional props for the input field.
 * @param {Object} [props.popoverProps] - Additional props for the popover component.
 * @returns {JSX.Element} The rendered select component.
 */
const Select = ({
  onChange,
  options,
  chosenIndicator,
  clearOnEmpty,
  inputProps,
  popoverProps,
  label,
  id,
  defaultValue,
}: SelectProps) => {
  const selectId = id ?? `select-${Math.random().toString(36).substr(2, 9)}`;
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionSelect | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<OptionSelect[]>(options);

  const toggleOpen = (b?: boolean) => {
    if (inputProps?.disabled) return;
    setOpen(b ?? !open);
  };

  useEffect(() => {
    if (options.length > 0) setFilteredOptions(options);
  }, [options]);
  /**
   * Handles the selection of an option.
   * @param {OptionSelect|string} option - The selected option.
   */
  function handleSelect(option: OptionSelect) {
    if (typeof option === "string") {
      setInputValue(option);
      setSelectedOption({ value: option, label: option });
    } else {
      setInputValue(option.label ?? option.value);
      setSelectedOption(option);
    }

    onChange(option);
    toggleOpen(false);
    setFilteredOptions(options);
  }

  function handleInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setInputValue(value);
    const filtered = options.filter((option) =>
      typeof option === "string"
        ? textNormalize(option).includes(textNormalize(value))
        : textNormalize(option.label ?? "").includes(textNormalize(value))
    );
    setFilteredOptions(filtered);
    if (clearOnEmpty && value === "") {
      setSelectedOption(null);
      onChange(null);
    }
  }

  // Handles click outside the popover to close it.
  function onOutsideClick() {
    toggleOpen(false);
    setInputValue(
      selectedOption ? (typeof selectedOption === "string" ? selectedOption : selectedOption.label ?? "") : ""
    );

    setFilteredOptions(options);
  }

  useEffect(() => {
    if (!defaultValue) return;
    handleSelect(defaultValue);
  }, []);
  return (
    <Popover
      open={open}
      onOpenChange={() => onOutsideClick()}
      placement={popoverProps?.placement ? popoverProps.placement : "bottom-start"}
      withReferenceWidth={popoverProps?.withReferenceWidth ?? true}
      dismiss={popoverProps?.dismiss ?? true}
    >
      {/* Select input */}
      <PopoverTrigger tw="w-full relative flex flex-col gap-2">
        {label && (
          <Label sizeVariant="md" htmlFor={selectId} disabled={inputProps?.disabled}>
            {label}
          </Label>
        )}
        <StyledSelectInput
          id={selectId}
          type="text"
          endIcon={<StyledIconChevronDown tw="cursor-pointer" $open={open} onClick={() => toggleOpen()} />}
          {...inputProps}
          value={inputValue}
          readOnly={!open}
          $cursor={!open ? "pointer" : "text"}
          onChange={handleInputChanged}
          onClick={() => {
            if (!open) {
              toggleOpen(true);
            }
          }}
        />
      </PopoverTrigger>
      {/* Select options */}
      <PopoverContent
        classes={{
          root: css`
            ${tw`z-[52]  overflow-y-auto flex flex-col gap-s`}
            ${filteredOptions.length >= 6 ? tw`h-80` : tw`h-max`}
          `,
        }}
      >
        {filteredOptions.length > 0 &&
          filteredOptions.map((option, index) => {
            const isSelected =
              selectedOption === option ||
              (typeof selectedOption !== "string" && selectedOption?.value === option);

            return (
              <PopoverButton
                key={index}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  handleSelect(option);
                }}
                startIcon={isSelected ? chosenIndicator ?? null : null}
                css={
                  isSelected &&
                  css`
                    ${tw`bg-neutral-99`}
                  `
                }
                tw="text-left medium:(text-center)"
              >
                {typeof option === "string" ? option : option.label}
              </PopoverButton>
            );
          })}
        {filteredOptions.length === 0 && (
          <Paragraph sizeVariant="md" tw="text-center mt-3">
            No se encontraron resultados
          </Paragraph>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default Select;
