const CorrectCityNames = {
  bogota: "Bogotá",
  barranquilla: "Barranquilla",
  medellin: "Medellín",
};

export default CorrectCityNames;

export const getCorrectCityName = (cityKey: string) => {
  if (!cityKey) return cityKey;
  return CorrectCityNames?.[cityKey.toLowerCase()] || cityKey;
};
