import { theme } from "twin.macro";
import Caption from "../Caption";
import { StyledDividerTagsVR3, StyledTagsVR3Root, SyleDividerDotTagsVR3 } from "./styles";
import { isArray } from "lodash";
import { darkenHex } from "@/utils/color";
import Tooltip, { TooltipContent, TooltipTrigger } from "@/components/Atoms/Tooltip";
import Paragraph from "../Paragraph";

interface TagsVR3Props {
  label: string | string[] | undefined;
  color?: string;
  type: "customLabel" | "discount" | "sponsor" | "free";
  separator?: "line" | "dot";
}

const colorsByType = {
  discount: {
    separatorColor: theme`colors.secondary.40`,
    background: theme`colors.secondary.90`,
    borderColor: theme`colors.secondary.40`,
    textColor: theme`colors.secondary.40`,
  },
  sponsor: {
    separatorColor: theme`colors.secondary.40`,
    background: theme`colors.neutral.90`,
    borderColor: theme`colors.neutral.70`,
    textColor: theme`colors.neutral.30`,
  },
  free: {
    separatorColor: "transparent",
    background: theme`colors.tertiary.60`,
    borderColor: "transparent",
    textColor: theme`colors.neutral.100`,
  },
};

const TagsVR3 = ({ label = "antimerma", color, type, separator = "line" }: TagsVR3Props) => {
  const hasCustomLabel = type === "customLabel";
  const hasSponsor = type === "sponsor";

  const assignedColor = hasCustomLabel ? color : colorsByType[type].separatorColor;
  const assignedBackground = hasCustomLabel ? color : colorsByType[type].background;
  const assignedBorderColor = hasCustomLabel ? color : colorsByType[type].borderColor;
  const assignedTextColor = hasCustomLabel ? darkenHex(color!, 0.7) : colorsByType[type].textColor;

  const renderLabelContent = () => {
    if (isArray(label)) {
      return label.map((item, index) => {
        const isLast = index === label.length - 1;
        return (
          <div key={index} tw="flex h-full gap-s">
            <Caption fontWeight={700} tw="w-max" color={assignedTextColor}>
              {item}
            </Caption>
            {!isLast &&
              (separator === "line" ? (
                <StyledDividerTagsVR3 $color={assignedColor} />
              ) : (
                <SyleDividerDotTagsVR3 $color={assignedColor} />
              ))}
          </div>
        );
      });
    }
    return (
      <Caption fontWeight={700} color={assignedTextColor}>
        {label}
      </Caption>
    );
  };

  const tagContent = (
    <StyledTagsVR3Root
      $borderStyle={hasSponsor ? "solid" : "dashed"}
      $useTransparent={hasCustomLabel}
      $background={assignedBackground}
      $borderColor={assignedBorderColor}
    >
      {renderLabelContent()}
    </StyledTagsVR3Root>
  );

  return hasCustomLabel ? (
    <Tooltip placement="bottom-end">
      <TooltipTrigger>{tagContent}</TooltipTrigger>
      <TooltipContent tw="max-w-[14.313rem] z-10">
        <Paragraph sizeVariant="sm">Producto en excelente estado, con pronta vida útil.</Paragraph>
      </TooltipContent>
    </Tooltip>
  ) : (
    tagContent
  );
};

export default TagsVR3;
