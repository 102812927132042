import { API_URL_V2, API_URL_V1 } from "../constants";

export const orders = `${API_URL_V2}/orders/`;
export const order = (id: string) => `${API_URL_V2}/orders/${id}/`;
export const orderProducts = (id: string) => `${API_URL_V2}/orders/${id}/items/`;
export const orderProductsSKU = (id: string) => `${API_URL_V2}/orders/${id}/items/sku/`;
export const orderCancel = (id: string) => `${API_URL_V1}/orders/${id}/cancel/`;
export const orderReview = (id: string) => `${API_URL_V1}/orders/${id}/rate/`;
export const retryPayment = (id: string) => `${API_URL_V1}/orders/${id}/retry-payment/`;

export const createOrder = `${API_URL_V1}/orders/`;
