import tw, { styled } from "twin.macro";

const baseStyles = tw`flex flex-col gap-m pt-m bg-neutral-99 p-m `;

export const StyledCityFeedbackForm = styled.form`
  ${baseStyles}
`;

export const StyledCityFeedbackSuccess = styled.div<{ $withShadow: boolean; $defaultPadding: boolean }>`
  ${baseStyles}
  ${tw`items-center gap-m justify-center`}

  & > svg {
    ${tw`w-[9.375rem] h-[6.938rem]`}
  }
  ${({ $withShadow }) =>
    $withShadow ? tw`shadow-[inset_0px_4px_4px_0px_rgba(0,0,0,0.10)] min-h-[18.5rem]` : ""}

  ${({ $defaultPadding }) => $defaultPadding && tw`pt-[4.188rem] pb-[2.716rem]`}
`;
