import Popover, { PopoverContent, PopoverTrigger } from "@/components/Atoms/Popover";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { type IHub } from "@/types/hubs";
import { type Placement } from "@floating-ui/react";
import { useEffect, useMemo, useState } from "react";

import CategoryButton from "@/components/Atoms/CategoryButton";
import tw, { css, type TwStyle } from "twin.macro";
import Button from "@/components/Atoms/Button";
import Heading from "@/components/Atoms/Heading";
import { Link, useLocation } from "react-router-dom";
import { type CategoryNodeHeader } from "@/components/Organisms/Header/types";
import {
  StyledCategoryList,
  StyledContainerContent,
  StyledIconChevronDown,
  StyledSearchWrapper,
  StyledSection,
} from "./styles";
import SearchBar from "../SearchBar";
import IconEditClose from "@iconsV2/Edit/close.svg?react";
import { textNormalize } from "@/utils/textNormalize";
import Notification from "@/components/Atoms/Notification";

interface extraCategory {
  name: string;
  slug: string;
}

interface sectionCategory {
  title: string;
  data: CategoryNodeHeader[] | extraCategory[] | IHub[];
  basePath: string;
}

interface ListPopoverProps {
  placement?: Placement;

  classes?: TwStyle;

  notVisible?: boolean;
  data: {
    additionalMenuCategories?: sectionCategory;
    category: sectionCategory;
    hub?: sectionCategory;
  };
}
const CategoryList = ({
  placement = "bottom-start",
  classes: otherStyles,
  data,
  notVisible,
}: ListPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMedium = useMediaQuery("(min-width: {medium})");
  const isLarge = useMediaQuery("(min-width: {large})");
  const { pathname } = useLocation();
  const [value, setValue] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  const handleClean = () => {
    setValue("");
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [notVisible]);

  // Filter data based on input value
  const filteredSections = useMemo(() => {
    return Object.entries(data).reduce<Record<string, sectionCategory>>((acc, [key, section]) => {
      // only filter if it's not medium
      if (!isMedium && section) {
        const filteredData = section.data.filter((item) =>
          textNormalize(item.name).includes(textNormalize(value))
        );
        if (filteredData.length > 0) {
          acc[key] = { ...section, data: filteredData };
        }
      } else if (isMedium && section) {
        // If it is medium or larger, we do not filter, we simply copy the data
        acc[key] = { ...section };
      }
      return acc;
    }, {});
  }, [data, value, isMedium]);

  // Check if all sections are empty after filtering
  const allSectionsEmpty = useMemo(() => {
    return Object.values(filteredSections).every(
      (section) => !section || (section.data && section.data.length === 0)
    );
  }, [filteredSections]);

  return (
    <Popover
      open={isOpen}
      hidden={false}
      onOpenChange={isMedium ? setIsOpen : undefined}
      placement={placement}
      shift
      flip={false}
      fullScreen={!isMedium}
    >
      <PopoverTrigger>
        <CategoryButton
          endIcon={<StyledIconChevronDown $open={isOpen} />}
          label="Categorías"
          color="secondary"
          as="button"
          title="Categorías"
          onClick={() => setIsOpen(!isOpen)}
          classes={{
            root: css`
              ${tw`gap-0!`}
              ${otherStyles}
            `,
          }}
          sizeVariant="xs"
          fontWeight={600}
        />
      </PopoverTrigger>

      <PopoverContent
        classes={{
          root: tw`medium:(-ml-m border border-neutral-90)`,
        }}
      >
        {/* SearchBar */}
        {!isMedium && (
          <StyledSearchWrapper>
            <SearchBar
              inputProps={{
                value,
                onChange: handleInputChange,
                placeholder: "Busca por hubs y categoría",
              }}
              cleanInput={handleClean}
            />
            <Button
              sizeVariant="lg"
              onClick={() => {
                setIsOpen(false);
                handleClean();
              }}
              color="secondary"
              variant="outlined"
              startIcon={<IconEditClose />}
              tw="w-min h-max p-[0.313rem] self-center mt-[0.375rem]"
            />
          </StyledSearchWrapper>
        )}

        {/* Show message if all sections are empty */}
        {allSectionsEmpty ? (
          <>
            {!isMedium && (
              <StyledContainerContent>
                <Notification
                  messages="No encontramos resultados para la categoría que buscas. Intenta con otra palabra."
                  type="info"
                />
              </StyledContainerContent>
            )}
          </>
        ) : (
          <StyledContainerContent>
            {Object.entries(filteredSections).map(([key, section]) => {
              if (!section) return null;

              const categoryPath = pathname.split(section.basePath)[1];

              return (
                <StyledSection key={key}>
                  {!isLarge && <Heading type="h6">{section.title}</Heading>}
                  <StyledCategoryList
                    $orientation={isMedium && section.data.length > 3 ? "horizontal" : "vertical"}
                  >
                    {section.data.map((item, index) => {
                      const isActive = categoryPath === item.slug;

                      return (
                        <CategoryButton
                          className={`list-item ${isActive ? "list-item-active" : ""}`}
                          as={Link}
                          key={index}
                          label={item.name}
                          title={item.name}
                          to={`${section.basePath}${item.slug}`}
                          onClick={() => setIsOpen(false)}
                          color="secondary"
                          sizeVariant={isMedium ? "sm" : "md"}
                        />
                      );
                    })}
                  </StyledCategoryList>
                </StyledSection>
              );
            })}
          </StyledContainerContent>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default CategoryList;
