import { Link } from "react-router-dom";
import tw from "twin.macro";
import Card from "@/components/Atoms/Card";
import { type IHub } from "@/types/hubs";

import { StyledHubCardRoot, StyledImage } from "./styles";
import ButtonText from "../ButtonText";
import { useState } from "react";

interface IHubCardProps {
  hub: IHub;
  [key: string]: any;
}

const HubCard = ({ hub, ...rest }: IHubCardProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <StyledHubCardRoot className="swiper-slide">
      <Card
        to={`/h/${hub.slug}`}
        title={hub.name}
        as={Link}
        classes={{
          root: tw`border border-neutral-90 bg-transparent shadow-elevation-0 text-center flex flex-col justify-start items-center gap-2 py-m px-2 active:border-secondary-85
          h-[6.5rem] last:mr-10 medium:(py-4 px-3 min-h-[8rem]) large:(py-l px-4 h-[10rem]  last:mr-0) w-full 
          hover:bg-secondary-99`,
        }}
        {...rest}
      >
        <StyledImage
          src={hub.image.url}
          alt={hub.image.alt}
          title={hub.name}
          effect="blur"
          onLoad={() => setIsImageLoaded(true)}
        />

        <ButtonText
          sizeVariant="sm"
          tw=" max-w-[6.25rem] large:max-w-max"
          css={[
            tw` max-w-[6.25rem] large:max-w-max opacity-0 transition-opacity duration-300`,
            isImageLoaded && tw`opacity-100`,
          ]}
        >
          {hub?.name}
        </ButtonText>
      </Card>
    </StyledHubCardRoot>
  );
};

export default HubCard;
