import tw, { styled } from "twin.macro";

export const StyledWrapperHubsRoot = styled.div`
  ${tw`w-full mx-auto max-w-screen-large relative medium:px-m large:px-0`}

  .swipperNavButtonHubs {
    ${tw`opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out`}
  }

  &:hover .swipperNavButtonHubs {
    ${tw`medium:(opacity-100 pointer-events-auto)`}
  }
`;
export const StyledWrapperHubs = styled.div`
  ${tw`w-full my-1`}
`;

export const StyledWrapperLoader = styled.div`
  ${tw`medium:(max-w-screen-xl mx-auto)`}
`;

export const StyledButtonNextContainerHubs = styled.div`
  ${tw`absolute z-10 right-0 top-0  w-max h-full grid place-content-center invisible  medium:visible large:(-right-s)`}
`;

export const StyledButtonPrevContainerHubs = styled.div`
  ${tw`absolute z-10 [left: ] top-0  w-max h-full grid place-content-center invisible  medium:visible large:(-left-s)`}
`;
export const StyledHubsSkeletonWrapper = styled.div`
  ${tw`flex w-full gap-s max-w-screen-large`}
`;
