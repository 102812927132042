// credit: https://github.com/rt2zz/redux-persist-crosstab
import { KEY_PREFIX, REHYDRATE } from "redux-persist";
import type { PersistConfig } from "redux-persist/es/types";
import type { Store } from "redux";
import { getStateReduxFromLocalStorage } from "@/utils/state";
import type { AppState, rootReducer } from ".";
import { isEqual } from "lodash";

interface CrosstabConfig {
  blacklist?: string[] | null | undefined;
  keyPrefix?: string | null | undefined;
  whitelist?: string[] | null | undefined;
}

function crosstabSync(
  store: Store,
  persistConfig: PersistConfig<typeof rootReducer>,
  crosstabConfig: CrosstabConfig = {}
) {
  const blacklist: string[] | null | undefined = crosstabConfig.blacklist ?? null;
  const whitelist: string[] | null | undefined = crosstabConfig.whitelist ?? null;
  const keyPrefix: string = crosstabConfig.keyPrefix ?? KEY_PREFIX;

  const { key }: { key: string } = persistConfig;

  window.addEventListener("storage", handleStorageEvent, false);

  function handleStorageEvent(e: StorageEvent) {
    // KEY_PREFIX = "persist"

    // Check if the key is not a redux-persist key
    if (!isEqual(e?.key?.indexOf(keyPrefix), 0)) {
      return;
    }

    // Check if the old value is equal to the new value
    if (isEqual(e.oldValue, e.newValue)) {
      return;
    }

    if (!e.newValue) {
      return;
    }

    // Get the new state from localStorage
    const stateFromLS = getStateReduxFromLocalStorage(e.newValue)!;

    const state: AppState = Object.keys(stateFromLS).reduce((state, reducerKey) => {
      if (whitelist && !whitelist.includes(reducerKey)) {
        return state;
      }
      if (blacklist && blacklist.includes(reducerKey)) {
        return state;
      }

      state[reducerKey] = stateFromLS[reducerKey as keyof AppState];

      return state;
    }, {}) as AppState;

    store.dispatch({
      key,
      payload: state,
      type: REHYDRATE,
    });
  }
}

export default crosstabSync;
