import tw, { theme } from "twin.macro";
import Paragraph from "@atoms/Paragraph";
import DetailAmount from "@atoms/DetailAmount";
import CardDismissible, { type ICardDismissible } from "@atoms/CardDismissible";
import Tooltip, { TooltipContent, TooltipTrigger } from "@atoms/Tooltip";
import Loader from "@atoms/Loader";
import { formatCurrency } from "@/utils/money";
import type { TUseCheckoutSummary } from "@/hooks/useCheckoutSummary";
import IconFlash from "@iconsV2/Interrface/flash.svg?react";
import { StyledFreeDeliveryText, StyledFreeDeliveryTooltip } from "./styles";
import TagsVR3 from "@/components/Atoms/TagsVR3";
import IconAttentionTriangle from "@iconsV2/Warnings/attention-triangle-fill-none.svg?react";

interface ICheckoutSubtotals {
  title: string;
  summary: TUseCheckoutSummary;
  icon?: ICardDismissible["icon"];
}

const CheckoutSubtotals = ({ title, icon, summary }: ICheckoutSubtotals) => {
  const {
    discount,
    subTotal,
    service,
    coupon,
    totalItemsAndService,
    servicePriceMutation,
    location,
    creditsToUse,
  } = summary;

  return (
    <CardDismissible
      title={title}
      classes={{
        root: tw`flex flex-col gap-s`,
        head: tw`p-0`,
        content: tw`flex flex-col gap-xxs`,
      }}
      withBorder={false}
      icon={icon}
      defaultOpen
    >
      <DetailAmount
        title="Productos"
        amount={formatCurrency(subTotal)}
        sizeVariant="md"
        type="h6"
        color={theme`colors.neutral.40`}
      />

      {/* Service prices */}
      {service?.name && (
        <DetailAmount
          title={!location.isPickup ? `Envío (${service.name})` : service?.name}
          amount={
            !servicePriceMutation.isPending || location.isPickup ? (
              <>
                {location.isPickup || (service?.price?.gross && +service?.price?.gross === 0) ? (
                  <Tooltip dismiss arrow placement="left">
                    <TooltipTrigger>
                      <StyledFreeDeliveryText>
                        Gratis
                        <IconFlash tw="all-child:fill-tertiary-60" />
                      </StyledFreeDeliveryText>
                    </TooltipTrigger>

                    {service?.price?.message && (
                      <TooltipContent>
                        <StyledFreeDeliveryTooltip>
                          <Paragraph sizeVariant="sm">{service.price.message}</Paragraph>
                        </StyledFreeDeliveryTooltip>
                      </TooltipContent>
                    )}
                  </Tooltip>
                ) : (
                  formatCurrency(+(service?.price?.gross ?? 0))
                )}
              </>
            ) : (
              <Loader width={20} height={20} />
            )
          }
          sizeVariant="md"
          type="h6"
          color={theme`colors.neutral.40`}
        />
      )}

      <DetailAmount
        title="Subtotal"
        amount={formatCurrency(totalItemsAndService)}
        sizeVariant="md"
        type="h6"
      />

      {(discount || creditsToUse > 0 || coupon) && <hr tw="my-xxs" />}

      {discount > 0 && (
        <DetailAmount
          title="Descuentos"
          amount={`-${formatCurrency(discount)}`}
          sizeVariant="md"
          type="h6"
          color={theme`colors.neutral.40`}
        />
      )}

      {coupon && (
        <DetailAmount
          title={
            <>
              Cupón
              <TagsVR3 type="discount" label={[coupon?.code ?? `${coupon?.discountValue}%`]} />
            </>
          }
          amount={
            coupon?.isValid ? (
              `-${formatCurrency(coupon.finalValuePrice)}`
            ) : (
              <>
                Sin aplicar
                <IconAttentionTriangle fill={theme`colors.error.50`} />
              </>
            )
          }
          sizeVariant="md"
          type="h6"
          color={theme`colors.neutral.40`}
        />
      )}

      {creditsToUse > 0 && (
        <DetailAmount
          title="Fitpoints"
          amount={`-${formatCurrency(creditsToUse)}`}
          sizeVariant="md"
          type="h6"
          color={theme`colors.neutral.40`}
        />
      )}
    </CardDismissible>
  );
};

export default CheckoutSubtotals;
