import srcBogota from "@/assets/common/fithub-bogota.webp";
import srcBarranquilla from "@/assets/common/fithub-barranquilla.webp";
import srcMedellin from "@/assets/common/fithub-medellin.webp";
import IconBadgedBarranquilla from "@components/Icons/V2/Illustration/badged-barranquilla.svg?react";
import IconBadgedBogota from "@components/Icons/V2/Illustration/badged-bogota.svg?react";
import IconBadgedMedellin from "@components/Icons/V2/Illustration/badged-medellin.svg?react";

export interface CityAddress {
  city: string;
  address: string;
  to: string;
  hour: string;
  weekdayOpenTime: string;
  weekdayCloseTime: string;
  sundayOpenTime: string;
  sundayCloseTime: string;
}

export default [
  {
    city: "Barranquilla",
    address: "Cra 51 # 82-223",
    to: "https://maps.app.goo.gl/rqZ911Gd6M9LBRLYA",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
    weekdayOpenTime: "08:00",
    weekdayCloseTime: "20:00",
    sundayOpenTime: "10:00",
    sundayCloseTime: "19:00",
    district: "Centro Historico",
    image: srcBarranquilla,
    badge: IconBadgedBarranquilla,
    about:
      "Ubicados en el corazón del centro histórico de Barranquilla, abrimos nuestra tienda física en el 2020 y hemos crecido gracias al respaldo de la comunidad, que nos reconoce como su alternativa saludable.",
  },
  {
    city: "Bogotá",
    address: "Calle 93a #11-46",
    to: "https://maps.app.goo.gl/dbjBn4ZmpyAx2tv77",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
    weekdayOpenTime: "08:00",
    weekdayCloseTime: "20:00",
    sundayOpenTime: "10:00",
    sundayCloseTime: "19:00",
    district: "Chapinero",
    image: srcBogota,
    badge: IconBadgedBogota,
    about:
      "En el corazón vibrante de Chapinero, Bogotá nos recibió desde  el 2021, y a lo largo de los años, hemos crecido de la mano de su comunidad. Hoy, somos más que una opción; nos hemos convertido en la alternativa saludable que acompaña el estilo de vida de quienes buscan bienestar y equilibrio en la capital del país.",
  },
  {
    city: "Medellín",
    address: "Cra 30a #9-68",
    to: "https://maps.app.goo.gl/DoNmXsuXXoWLJ5YP7",
    hour: "Lun - Sab 8 am - 9 pm | Dom  8 am - 7 pm",
    weekdayOpenTime: "08:00",
    weekdayCloseTime: "21:00",
    sundayOpenTime: "08:00",
    sundayCloseTime: "19:00",
    district: "El Poblado",
    image: srcMedellin,
    badge: IconBadgedMedellin,
    about:
      "En la acogedora zona de El Poblado llegamos a Medellín en el 2025. Con el respaldo de su comunidad, esperamos crecer y consolidarnos en los próximos años como la alternativa saludable que acompaña el bienestar y estilo de vida de sus habitantes.",
  },
];
