import useAppSelector from "./useAppSelector";
import { useEffect, useMemo, useRef, useState } from "react";
import { type IService } from "./useCheckout/types";
import { useMutation } from "@tanstack/react-query";
import { getServiceDeliveryPrice } from "@/services/price";
import { gt, isEmpty, lt } from "lodash";
import { cleanNullValues } from "@/utils/orders";
import useCredits from "./useCredits";
import {
  selectCoupon,
  selectDiscount,
  selectItems,
  selectItemsStock,
  selectItemsWithoutDiscount,
  selectItemsWithDiscount,
  selectLocation,
  selectProcessedItems,
  selectSubTotal,
  selectTotal,
  selectTotalCountCart,
  selectTotalItemsWithoutDiscount,
} from "@/store/checkout/selector";
import useDebounce from "./useDebounce";
import { queryClient } from "@/recourses/queryClient";

interface IUseCheckoutSummary {
  service?: IService | null;
  canGetServicePrice?: boolean;
}

export default function useCheckoutSummary(props?: IUseCheckoutSummary) {
  const { service: serviceParam, canGetServicePrice = false } = props ?? {};

  const storeID = useAppSelector((state) => state.cart.storeId);
  const items = useAppSelector(selectItems);
  const itemsWithoutDiscount = useAppSelector(selectItemsWithoutDiscount);
  const itemsWithDiscount = useAppSelector(selectItemsWithDiscount);
  const subTotal = useAppSelector(selectSubTotal);
  const discount = useAppSelector(selectDiscount);
  const preTotal = useAppSelector(selectTotal);
  const totalItemsWithoutDiscount = useAppSelector(selectTotalItemsWithoutDiscount);
  const processedItems = useAppSelector(selectProcessedItems);
  const coupon = useAppSelector(selectCoupon);
  const itemsStock: Record<string, boolean> = useAppSelector(selectItemsStock);
  const totalCountCart = useAppSelector(selectTotalCountCart);

  const serviceFromRedux = useAppSelector((state) => state.checkout.service);
  const location = useAppSelector(selectLocation);
  const payment = useAppSelector((state) => state.checkout.payment);
  const hasCredit = useAppSelector((state) => state.checkout.useFitpoint && state.checkout.useFriendPoints);
  const shouldReceiveNotifications = useAppSelector((state) => state.checkout.sendWhatsApp);
  const gift = useAppSelector((state) => state.checkout.gift);
  const additionalComment = useAppSelector((state) => state.checkout.additionalComment);
  const deliveryTo = useAppSelector((state) => state.checkout.orderDeliveryTo);
  const [servicePriceData, setServicePriceData] = useState<getDeliveryPriceResponse | null>(null);
  const preTotalDebounce = useDebounce(preTotal, 500);
  const prevCouponRef = useRef<typeof coupon | null>(null);

  const service = !isEmpty(serviceParam) ? serviceParam : serviceFromRedux;

  const totalCountProcessedItems = useMemo(() => {
    return processedItems.length;
  }, [processedItems.length]);

  const keysQuery = [
    "servicePrice",
    service?.id,
    service?.date,
    coupon?.id,
    coupon?.isValid,
    location?.id,
    payment?.id,
    preTotalDebounce,
  ];

  const servicePriceMutation = useMutation({
    mutationKey: keysQuery,
    mutationFn: async () => {
      if (!canGetServicePrice) {
        return null;
      }

      if (location?.isPickup || !service?.id || !location?.id || preTotal <= 0) {
        return null;
      }

      const responseCache = queryClient.getQueryData<getDeliveryPriceResponse>(keysQuery);

      if (responseCache) {
        setServicePriceData(responseCache);
        return responseCache;
      }

      const data = cleanNullValues({
        id: service?.id,
        coupon: coupon?.id && coupon?.isValid ? coupon?.id : null,
        scheduleAt: service?.date,
        total: preTotal,
        address: location?.id?.toString(),
        lat: location?.latitude ? Number(location?.latitude) : null,
        lng: location?.longitude ? Number(location?.longitude) : null,
        items: processedItems.map((item) => item.product.sku),
      });

      const response: getDeliveryPriceResponse = await getServiceDeliveryPrice(data);

      await queryClient.fetchQuery({
        queryKey: keysQuery,
        queryFn: () => response,
        staleTime: 1000 * 60 * 10,
      });

      setServicePriceData(response);

      return response;
    },
  });

  const { totalCredits } = useCredits({
    creditsAvailable: true,
    referralCreditsAvailable: true,
  });

  const creditsToUse = useMemo(() => {
    if (!hasCredit) return 0;

    return preTotal > totalCredits ? totalCredits : preTotal;
  }, [totalCredits, hasCredit, preTotal]);

  const servicePrice = useMemo(() => {
    return (
      servicePriceData?.price && {
        ...(servicePriceData?.price ?? {}),
        isFree: Number(servicePriceData?.price?.gross ?? 0) === 0,
        name: service?.name,
        message: servicePriceData?.message,
      }
    );
  }, [servicePriceData?.price?.gross, service?.name]);

  const totalItemsAndService = useMemo(() => {
    const priceService = Number(servicePrice?.gross ?? 0);
    return subTotal + priceService;
  }, [subTotal, servicePrice?.gross]);

  const total = useMemo(() => {
    if (preTotal <= 0) {
      return 0;
    }

    const priceCredit = hasCredit ? totalCredits : 0;
    const priceService = location.isPickup ? 0 : +(servicePrice?.gross ?? 0);
    let couponAmount = 0;
    if (coupon?.isValid) {
      couponAmount = coupon.finalValuePrice;
    }

    const _total = preTotal - priceCredit - couponAmount;

    if (_total < 0) {
      return 0 + priceService;
    }

    return _total + priceService;
  }, [preTotal, servicePrice?.gross, hasCredit, totalCredits, coupon?.isValid, location.isPickup]);

  // Check if is a payment only of fitpoints
  const isFitpointsPayment = hasCredit && creditsToUse === preTotal;
  const isOnlyFitpointsPayment =
    isFitpointsPayment &&
    total === 0 &&
    (Boolean(servicePrice?.isFree) || +(servicePrice?.gross ?? 0) === 0 || location.isPickup);

  const minAmountForFreeDelivery = useMemo(() => {
    return (
      servicePriceData?.min_amount_for_free_delivery && {
        ...(servicePriceData?.min_amount_for_free_delivery ?? {}),
        can: Number(servicePriceData?.min_amount_for_free_delivery?.gross ?? 0) >= total,
      }
    );
  }, [servicePriceData?.min_amount_for_free_delivery?.gross, total]);

  const cuponAmount = useMemo(() => {
    if (!coupon?.isValid) {
      return 0;
    }

    return processedItems.reduce((acc, item) => {
      if (item.price.discountType === "coupon") {
        return acc + (item.price.coupon?.discountPerUnit ?? 0) * item.quantity;
      }
      return acc;
    }, 0);
  }, [JSON.stringify(processedItems), coupon?.isValid]);

  useEffect(() => {
    if (!canGetServicePrice) {
      return;
    }

    if (totalCountCart <= 0) {
      return;
    }

    if (!service?.id || !location?.id) {
      return;
    }

    if (servicePriceMutation.isPending) {
      return;
    }

    // Si el cupon cambia, se vuelve a hacer la peticion
    // Esto es para evitar que se hagan peticiones innecesarias
    const couponChanged =
      prevCouponRef.current?.id !== coupon?.id || prevCouponRef.current?.isValid !== coupon?.isValid;

    if (couponChanged) {
      servicePriceMutation.mutate();
    }

    prevCouponRef.current = coupon;

    // ?? Esta es una idea de @jBarrios, yo @rsanchez no entendi :D
    // Si el servicio es gratis y el monto minimo para el envio gratis es mayor al total
    // O si el servicio no es gratis y el monto minimo para el envio gratis es menor al total
    // No se hace la peticion
    // Esto es para evitar que se hagan peticiones innecesarias
    // Si el monto minimo para el envio gratis es mayor al total, no se puede hacer el envio gratis
    // Si el monto minimo para el envio gratis es menor al total, no se puede hacer el envio gratis
    const minAmountForFreeDelivery = Number(servicePriceData?.min_amount_for_free_delivery?.gross ?? 0);
    if (
      (!!servicePrice?.isFree && lt(minAmountForFreeDelivery, preTotal)) ||
      (!servicePrice?.isFree && gt(minAmountForFreeDelivery, preTotal))
    ) {
      return;
    }

    servicePriceMutation.mutate();
  }, [
    service?.id,
    service?.date,
    coupon?.id,
    location?.id,
    payment?.id,
    preTotalDebounce,
    coupon?.isValid,
    coupon?.hasFreeDelivery,
    servicePrice?.isFree,
    servicePriceData?.min_amount_for_free_delivery?.gross,
    canGetServicePrice,
  ]);

  return {
    servicePriceMutation: {
      ...servicePriceMutation,
      data: servicePriceData,
    },
    items,
    itemsWithoutDiscount,
    itemsWithDiscount,
    subTotal,
    discount,
    preTotal,
    total,
    totalItemsWithoutDiscount,
    totalItemsAndService,
    totalCredits,
    processedItems,
    service: {
      ...service,
      price: servicePrice,
      minAmountForFreeDelivery,
    },
    minAmountForFreeDelivery,
    location,
    payment,
    hasCredit,
    storeID,
    shouldReceiveNotifications,
    gift,
    additionalComment,
    itemsStock,
    totalCountProcessedItems,
    deliveryTo,
    coupon: coupon && {
      ...coupon,
      mismatchPrice: {
        original: coupon?.finalValuePrice,
        calculated: cuponAmount,
        mismatch: coupon?.finalValuePrice !== cuponAmount,
      },
      amount: cuponAmount,
    },
    totalCountCart,
    isFitpointsPayment,
    isOnlyFitpointsPayment,
    creditsToUse,
  };
}

export type TUseCheckoutSummary = ReturnType<typeof useCheckoutSummary>;
