import http from "@/utils/http";
import { BASE_API_URL } from "@/recourses/constants";
import * as urls from "@/recourses/urls/checkout";
import type { QuickStartResponse, IGetAvailableProductsResponse } from "./type";
import { snakeToCamelCase } from "@/utils/caseConverters";

export const getCartAvailableProducts = async (
  storeId: number,
  productsId: string
): Promise<IGetAvailableProductsResponse | undefined> => {
  try {
    const response = await http.get(
      `${BASE_API_URL}api/v1/stores/${storeId}/available-products/?products=${productsId}
`,
      { isAuth: false }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getShippingInfo = async (
  storeId: string | number,
  lat: number,
  lng: number
): Promise<AvailableServiceAttr[] | undefined> => {
  const response = await http.get(urls.shippingInfo(storeId, lat, lng), {
    isAuth: true,
  });
  return response.data;
};

export const getQuickStart = async (): Promise<QuickStartResponse | undefined> => {
  const response = await http.get(urls.quickStart, {
    isAuth: true,
  });
  return snakeToCamelCase(response.data) as QuickStartResponse;
};
