import { useState } from "react";
import type { TSize } from "@/types/common";
import IconUserCircle from "@iconsV2/Users/user-circle-normal.svg?react";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import useSessionContext from "@/hooks/useSesionContext";

import ProfileMenu from "../ProfileMenu";
import PrimaryButtonHeader from "@components/Molecules/PrimaryButtonHeader";
import tw from "twin.macro";

interface IProfileButtonProps {
  label: string;
  firstName?: string;
  size?: TSize;
  onClickLogin: () => void;
  [key: string]: any;
}

const ProfileButton = ({ label, firstName, size, onClickLogin, ...props }: IProfileButtonProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const { isAthenticated } = useSessionContext();

  const handleOpenMenu = (open: boolean) => {
    setOpenMenu(open);
  };

  if (!isAthenticated()) {
    return (
      <PrimaryButtonHeader
        onClick={onClickLogin}
        icon={<IconUserCircle />}
        title="Iniciar sesión"
        classes={{
          svg: tw`w-[1.875rem] h-[1.875rem] medium:(w-5 h-5) large:(w-6 h-6)`,
        }}
      >
        {isMedium && "Iniciar sesión"}
      </PrimaryButtonHeader>
    );
  }

  return (
    <ProfileMenu nameUser={label} openMenu={openMenu} handleOpenMenu={handleOpenMenu}>
      <PrimaryButtonHeader
        title="Perfil"
        icon={<IconUserCircle />}
        onClick={() => handleOpenMenu(!openMenu)}
        classes={{
          svg: tw`w-[1.875rem] h-[1.875rem] medium:(w-5 h-5) large:(w-6 h-6)`,
        }}
      >
        {isMedium && (
          <>
            Hola <span tw="capitalize">{firstName}</span>
          </>
        )}
      </PrimaryButtonHeader>
    </ProfileMenu>
  );
};

export default ProfileButton;
