import { useEffect, useState } from "react";

const useMounted = (delay?: number) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!delay) {
      setMounted(true);
      return;
    }

    const timer = setTimeout(() => {
      setMounted(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return mounted;
};

export default useMounted;
