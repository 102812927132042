import http from "@/utils/http";

import { deliveryPrice } from "@/recourses/urls/checkout";

export const getDeliveryPrice = async ({
  id,
  coupon,
  schedule_at,
  total,
  payment_method,
  address,
  lat,
  lng,
  items,
}: getDeliveryPriceParams): Promise<getDeliveryPriceResponse> => {
  const response = await http.post<getDeliveryPriceResponse>(deliveryPrice(id), {
    schedule_at,
    total,
    coupon,
    payment_method,
    address,
    lat,
    lng,
    items,
  });

  return response.data;
};

export const getServiceDeliveryPrice = async ({
  id,
  coupon,
  scheduleAt,
  total,
  paymentMethod,
  address,
  lat,
  lng,
  items,
}: IServiceDeliveryPriceParams): Promise<getDeliveryPriceResponse> => {
  const response = await http.post<getDeliveryPriceResponse>(deliveryPrice(id), {
    scheduleAt,
    total,
    coupon,
    paymentMethod,
    address,
    lat,
    lng,
    items,
  });

  return response.data;
};
