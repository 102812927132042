/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/**
 * Convierte una cadena de snake_case a camelCase.
 * @param {string} str - La cadena en formato snake_case.
 * @returns {string} La cadena convertida a camelCase.
 */
export function snakeToCamel(str: string): string {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));
}

/**
 * Tipo que convierte una cadena de snake_case a camelCase a nivel de tipo.
 */
type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>;

/**
 * Tipo que convierte todas las claves de un objeto o array de snake_case a camelCase de forma recursiva.
 */
type CamelizeKeys<T> =
  T extends Array<infer U>
    ? Array<CamelizeKeys<U>>
    : T extends object
      ? {
          [K in keyof T as CamelCase<string & K>]: CamelizeKeys<T[K]>;
        }
      : T;

/**
 * Convierte todas las claves de un objeto o array de snake_case a camelCase de forma recursiva.
 * @template T
 * @param {T} obj - El objeto o array a convertir.
 * @returns {CamelizeKeys<T>} El objeto o array con todas sus claves en camelCase.
 */
export function snakeToCamelCase<T>(obj: T): CamelizeKeys<T> {
  if (typeof obj !== "object" || obj === null) {
    return obj as CamelizeKeys<T>;
  }

  if (Array.isArray(obj)) {
    return obj.map((value) => snakeToCamelCase(value)) as CamelizeKeys<T>;
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
    const camelKey = snakeToCamel(key) as keyof CamelizeKeys<T>;
    result[camelKey] = snakeToCamelCase(value);
    return result;
  }, {} as CamelizeKeys<T>);
}

/**
 * Convierte una cadena de camelCase a snake_case.
 * @param {string} str - La cadena en formato camelCase.
 * @returns {string} La cadena convertida a snake_case.
 */
export function camelToSnake(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

/**
 * Tipo que convierte una cadena de camelCase a snake_case a nivel de tipo.
 */
type SnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${SnakeCase<U>}`
  : S;

/**
 * Tipo que convierte todas las claves de un objeto o array de camelCase a snake_case de forma recursiva.
 */
type SnakeizeKeys<T> =
  T extends Array<infer U>
    ? Array<SnakeizeKeys<U>>
    : T extends object
      ? {
          [K in keyof T as SnakeCase<string & K>]: SnakeizeKeys<T[K]>;
        }
      : T;

/**
 * Convierte todas las claves de un objeto o array de camelCase a snake_case de forma recursiva.
 * @template T
 * @param {T} obj - El objeto o array a convertir.
 * @returns {SnakeizeKeys<T>} El objeto o array con todas sus claves en snake_case.
 */
export function camelToSnakeCase<T>(obj: T): SnakeizeKeys<T> {
  if (typeof obj !== "object" || obj === null) {
    return obj as SnakeizeKeys<T>;
  }

  if (Array.isArray(obj)) {
    return obj.map((value) => camelToSnakeCase(value)) as SnakeizeKeys<T>;
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
    const snakeKey = camelToSnake(key) as keyof SnakeizeKeys<T>;
    (result as any)[snakeKey] = camelToSnakeCase(value);
    return result;
  }, {} as SnakeizeKeys<T>);
}
