import React from "react";
import { StyledLoaderCircleBold, StyledLoaderCircleLigth, StyledLoaderDotRoot } from "./styles";
import { type CSSProperties } from "styled-components";

export interface LoaderProps {
  variant?: "circleBold" | "dot" | "circleLigth";
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  animation?: "bounce" | "pulse1";
}

const Loader = ({ variant = "circleBold", width = "2rem", height = "2rem", animation }: LoaderProps) => {
  return (
    <React.Fragment>
      {variant === "circleBold" && <StyledLoaderCircleBold $width={width} $height={height} />}

      {variant === "circleLigth" && <StyledLoaderCircleLigth $width={width} $height={height} />}
      {variant === "dot" && (
        <StyledLoaderDotRoot $width={width} $height={height} $animation={animation}>
          <div />
          <div />
          <div />
        </StyledLoaderDotRoot>
      )}
    </React.Fragment>
  );
};

export default Loader;
