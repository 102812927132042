import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import tw, { theme } from "twin.macro";
import Heading from "@atoms/Heading";
import Paragraph from "@atoms/Paragraph";
import Button from "@atoms/Button";
import DetailAmount from "@atoms/DetailAmount";
import CardDismissible from "@atoms/CardDismissible";
import PaymentServices from "@molecules/PaymentServices";
import useModalAuth, { CALLBACK_MODAL_AUTH } from "@/hooks/useModalAuth";
import { formatCurrency } from "@/utils/money";
import useAppSelector from "@/hooks/useAppSelector";
import ModalItemsOutOfStock from "@/components/Organisms/ModalItemsOutOfStock";
import useAddressModal, { CALLBACK_MODAL_ADDRESS } from "@/hooks/useAddressModal";
import useKeepCartFresh from "@/hooks/useKeepCartFresh";
import useCheckoutSummary from "@/hooks/useCheckoutSummary";
import { ECheckoutURL } from "@/hooks/useCheckout/types";
import ProductCardGallerySearch from "../ProductCardGalllerySearch";
import CartEmpty from "../CartEmpty";
import CheckoutSubtotals from "../CheckoutSubtotals";
import {
  StyledWrapper,
  StyledWrapperHeader,
  StyledWrapperBody,
  StyledWrapperProducts,
  StyledWrapperAmounts,
  StyledWrapperContainerScroll,
  StyledWrapperContentBottom,
} from "./styles";

interface IShoppingCartMenu {
  onAction: () => void;
  onClose: () => void;
}

const ShoppingCartMenu = ({ onAction, onClose }: IShoppingCartMenu) => {
  useKeepCartFresh();

  const popUpsReminder = useAppSelector((state) => state.popUpsReminder.showLocationModal);

  const { toggleAddressModal } = useAddressModal();
  const scrollDivRef = useRef<HTMLDivElement | null>(null);
  const contentBottomRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const summary = useCheckoutSummary({ canGetServicePrice: true });
  const { total, totalCountProcessedItems, processedItems, itemsStock } = summary;

  const { handleOpenModalAuth, isAthenticated } = useModalAuth();

  const availableStores = useAppSelector((state) => state.cart.availableStores.stores);
  const storeId = availableStores?.[0]?.id;

  const [openModalItemsOutStock, setOpenModalItemsOutStock] = useState<boolean>(false);

  const cartCheckoutDisabled = useMemo(() => {
    return (
      processedItems.length === 0 ||
      processedItems.some((item) => {
        // Allow if it is a product without stock
        if (!item.details.available) {
          return false;
        }

        // Validate if quantity is more than maximum available
        return item.quantity > item.details.maxQtyToOrder;
      })
    );
  }, [JSON.stringify(processedItems)]);

  const handleSubmitCheckout = () => {
    const userAuth = isAthenticated();

    if (!userAuth) {
      handleOpenModalAuth({
        callbackIdentifier: !popUpsReminder ? CALLBACK_MODAL_AUTH.NAVIGATE_TO_CHECKOUT : undefined,
        initialStep: "login",
      });
      return;
    }

    if (popUpsReminder) {
      toggleAddressModal(true, CALLBACK_MODAL_ADDRESS.NAVIGATE_TO_CHECKOUT);
      onClose?.();
      return;
    }

    navigate("/checkout");
  };

  const handleCheckout = () => {
    // There are not products go to shopping cart
    if (cartCheckoutDisabled) {
      navigate(ECheckoutURL.SHOPPING_CART_PAGE);
      return;
    }

    // There are products go to checkout
    if (processedItems.some((item) => !item.details.available)) {
      setOpenModalItemsOutStock(true);
      return;
    }

    handleSubmitCheckout();
  };

  const handleOnSubmitItemOutStock = () => {
    handleSubmitCheckout();
    setOpenModalItemsOutStock(false);
    onAction();
  };

  useEffect(() => {
    const $scrollDiv = scrollDivRef.current;
    const $contentBottom = contentBottomRef.current;

    if ($scrollDiv && $contentBottom) {
      const checkScroll = () => {
        const { scrollHeight, clientHeight, scrollTop } = $scrollDiv;
        const hasScroll = scrollHeight > clientHeight;
        const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;

        if (hasScroll && !isAtBottom) {
          $contentBottom.style.boxShadow = theme`boxShadow.elevation-top`;
        } else {
          $contentBottom.style.boxShadow = "none";
        }
      };

      checkScroll();

      $scrollDiv.addEventListener("scroll", checkScroll);
      return () => $scrollDiv.removeEventListener("scroll", checkScroll);
    }
  }, [scrollDivRef.current, contentBottomRef.current, processedItems.length]);

  return (
    <>
      <StyledWrapper>
        <StyledWrapperBody>
          <StyledWrapperHeader>
            <Heading type="h5">Tu pedido</Heading>

            <Paragraph sizeVariant="sm" color={theme`colors.neutral.50`}>
              {totalCountProcessedItems} {totalCountProcessedItems !== 1 ? "Productos" : "Producto"}
            </Paragraph>
          </StyledWrapperHeader>

          <StyledWrapperContainerScroll ref={scrollDivRef}>
            <StyledWrapperProducts>
              {processedItems.length > 0 ? (
                <>
                  {[...processedItems]
                    .sort((a) => {
                      return a.details.available ? -1 : 1;
                    })
                    .map((item) => {
                      if (item.details.available) {
                        // Product available
                        return (
                          <ProductCardGallerySearch
                            item={item}
                            orientation="horizontal"
                            disabled={!itemsStock?.[item.product.sku]}
                            showUnits
                            sizeCounterInput="small"
                            extraValidation={{
                              units: !itemsStock?.[item.product.sku],
                            }}
                            key={item.product.id}
                            onCloseShoppingCartMenu={onClose}
                          />
                        );
                      }

                      // Product doesnt available
                      return (
                        <CardDismissible
                          key={item.product.id}
                          title="Este producto está agotado"
                          classes={{
                            root: tw`bg-neutral-99 p-s`,
                          }}
                          defaultOpen
                        >
                          <ProductCardGallerySearch
                            item={item}
                            orientation="horizontal"
                            disabled
                            showUnits
                            sizeCounterInput="small"
                            extraValidation={{
                              units: true,
                            }}
                            key={item.product.id}
                            onCloseShoppingCartMenu={onClose}
                          />
                        </CardDismissible>
                      );
                    })}
                </>
              ) : (
                <CartEmpty />
              )}
            </StyledWrapperProducts>
          </StyledWrapperContainerScroll>
        </StyledWrapperBody>

        {/* Responsive bottom modal */}
        <StyledWrapperContentBottom ref={contentBottomRef}>
          <StyledWrapperAmounts>
            <CheckoutSubtotals title="Subtotal" summary={summary} />

            <DetailAmount title="Total" amount={formatCurrency(total)} withIVA sizeVariant="lg" />
          </StyledWrapperAmounts>

          <Button sizeVariant="lg" fullWidth onClick={handleCheckout}>
            {cartCheckoutDisabled ? "Añadir al carrito" : "Realizar pedido"}
          </Button>

          <PaymentServices />
        </StyledWrapperContentBottom>

        {/* Recommend product */}
        {/* <StyledWrapperContent tw="bg-neutral-99">
          <Heading type="h5">Te recomendamos llevar este producto</Heading>

          <StyledWrapperProducts>
            <ProductCardGallerySearch
              item={baseProduct}
              sizeVariant="sm"
              orientation="horizontal"
            />
          </StyledWrapperProducts>
        </StyledWrapperContent> */}
      </StyledWrapper>

      {openModalItemsOutStock && (
        <ModalItemsOutOfStock
          isOpen={openModalItemsOutStock}
          handleClose={() => setOpenModalItemsOutStock(false)}
          storeId={storeId}
          onSubmit={handleOnSubmitItemOutStock}
          onlyClose
        />
      )}
    </>
  );
};

export default ShoppingCartMenu;
