import { type RuleSet } from "styled-components";
import tw, { styled, type TwStyle } from "twin.macro";

interface IStyledPrimaryButtonHeaderRootProps {
  $classess?: RuleSet<object> | TwStyle;
}
export const StyledPrimaryButtonHeaderRoot = styled.div<IStyledPrimaryButtonHeaderRootProps>`
  ${tw`cursor-pointer w-max flex flex-col items-center rounded-s  `}
  & > button {
    ${tw`py-0`}
  }

  & svg {
    path {
      ${tw`fill-secondary-40`}
    }

    ${({ $classess }) => {
      if ($classess) {
        return $classess;
      } else {
        return tw`w-5 h-5 large:(w-6 h-6 )`;
      }
    }}
  }

  &:hover .text-primaryButtonHeader {
    ${tw`underline text-secondary-60`}
  }
`;
