import { QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { persistor, store } from "../store";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "../graphql/client";
import "moment/locale/es";
import * as Sentry from "@sentry/react";
import { SessionProvider } from "../context";
import GlobalStyles from "@/styles/GlobalStyles";
import { queryClient } from "@/recourses/queryClient";
import routes from "@/routes";
import Error503 from "@/Pages/Error503";
import { HelmetProvider } from "react-helmet-async";
import errorBoundaryBeforeCapture from "@/recourses/errorBoundaryBeforeCapture";
import { verifyTokenJWT } from "@/services/auth";
import { useEffect, useState } from "react";
import { userAction } from "@/store/user";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      staleTime: Infinity,
    },
  },
});

export const App = () => {
  const [loading, setLoading] = useState<boolean>(false);

  async function makeRequest() {
    if (!window.localStorage.getItem("userToken")) {
      store.dispatch(userAction.emptyUser());
      return;
    }

    setLoading(true);
    try {
      const data = await queryClient.fetchQuery<{ token: string; user: UserAttr }>({
        queryKey: ["verifyTokenJWT"],
        queryFn: verifyTokenJWT,
      });

      store.dispatch(
        userAction.setUser({
          id: data.user.id,
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          email: data.user.email,
          phoneNumber: data.user.telephone,
          creditAvaliable: {
            gross: data.user.credit_available.gross,
            grossLocalized: data.user.credit_available.gross_localized,
            net: data.user.credit_available.net,
            netLocalized: data.user.credit_available.net_localized,
            currency: data.user.credit_available.currency,
          },
          referralCreditAvailable: {
            gross: data.user.referral_credit_available.gross,
            grossLocalized: data.user.referral_credit_available.gross_localized,
            net: data.user.referral_credit_available.net,
            netLocalized: data.user.referral_credit_available.net_localized,
            currency: data.user.referral_credit_available.currency,
          },
          birthday: data.user.dob,
          gender: data.user.gender,
          hearAboutUs: data.user.hear_about_us_source,
          publicId: data.user.public_id,
          identification: data.user.identification,
          identificationType: data.user.identification_type,
          membership_savings: data.user.membership_savings,
          membership: data.user.membership,
        })
      );

      return data;
    } catch (error) {
      window.localStorage.removeItem("userToken");
      store.dispatch(userAction.emptyUser());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    void makeRequest();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Sentry.ErrorBoundary beforeCapture={errorBoundaryBeforeCapture} fallback={<Error503 hScreen />}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <ReactQueryCacheProvider queryCache={queryCache}>
                <SessionProvider>
                  <GlobalStyles />
                  <HelmetProvider>
                    <RouterProvider router={routes} />
                  </HelmetProvider>
                </SessionProvider>
                <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
              </ReactQueryCacheProvider>
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};
