import { StyledContainerIconOrder, StyledNotification } from "./styles";
import IconShoppingBag from "@iconsV2/Interrface/shopping-bag.svg?react";

interface IconOrderRoundProps {
  isScrolled: boolean;
  isNotificationBadgeVisible?: boolean;
  isRound?: boolean;
}
export const IconOrderRound = ({ isScrolled, isNotificationBadgeVisible, isRound }: IconOrderRoundProps) => {
  return (
    <StyledContainerIconOrder $isScrolled={isScrolled} $isRound={isRound}>
      <IconShoppingBag />
      {isNotificationBadgeVisible && <StyledNotification $isScrolled={isScrolled} />}
    </StyledContainerIconOrder>
  );
};

export default IconOrderRound;
