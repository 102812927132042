import "twin.macro";

import srcPse from "@/assets/cart/pse.webp";
import srcNequi from "@/assets/cart/nequi.webp";
import srcBancolombia from "@/assets/cart/bancolombia.webp";
import srcLyra from "@/assets/cart/lyra.webp";
import { StyledIconMastercard, StyledIconVisa, StyledImage } from "./styles";

const PaymentServices = () => {
  return (
    <div tw="flex items-center justify-between gap-xs">
      <div tw="flex flex-wrap items-center gap-s medium:(gap-m)">
        <StyledIconMastercard />
        <StyledIconVisa />
        <StyledImage src={srcPse} />
        <StyledImage src={srcNequi} />
        <StyledImage src={srcBancolombia} />
      </div>

      <StyledImage src={srcLyra} />
    </div>
  );
};

export default PaymentServices;
