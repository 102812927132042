import "twin.macro";
import IconCartShopping from "@iconsV2/Cart/cart-shopping.svg?react";
import Paragraph from "@atoms/Paragraph";

const CartEmpty = () => {
  return (
    <div tw="flex flex-col items-center gap-s">
      <IconCartShopping tw="w-[9.188rem] h-[7.75rem]" />

      <Paragraph sizeVariant="md">Aún no tienes productos en tu carrito</Paragraph>
    </div>
  );
};

export default CartEmpty;
