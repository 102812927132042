import { EDeliveryOptions, type IService } from "@/hooks/useCheckout/types";
import { type IAdditionalComment, type IGift } from "@/types/checkout";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { storeGlobalActions } from "../actions";

export interface ISliceCheckout {
  coupon: CouponCheckout | null;
  service: IService | null;
  gift: IGift | undefined;
  address: AddressAttr | undefined;
  payment: PaymentMethods | null;
  pickupStore: PickUpStore | null;
  useFitpoint: boolean;
  useFriendPoints: boolean;
  sendWhatsApp: boolean;
  additionalComment: IAdditionalComment | undefined;
  date: string;
  subtotal: number;
  discount: number;
  orderDeliveryTo: {
    id: EDeliveryOptions;
    name?: string;
  } | null;
  errorMessageAPI: string | null;
  extraLoading?: boolean;
}

const initialState: ISliceCheckout = {
  coupon: null,
  service: null,
  gift: undefined,
  address: undefined,
  pickupStore: null,
  payment: null,
  useFitpoint: false,
  useFriendPoints: false,
  sendWhatsApp: true,
  additionalComment: undefined,
  date: "",
  subtotal: 0,
  discount: 0,
  orderDeliveryTo: {
    id: EDeliveryOptions.SELF,
    name: "",
  },
  errorMessageAPI: null,
  extraLoading: false,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setAdditionalComment: (state, action: PayloadAction<IAdditionalComment | undefined>) => {
      state.additionalComment = action.payload;
    },
    setAdditionalCommentActive: (state, action: PayloadAction<boolean>) => {
      if (state?.additionalComment?.active === undefined) {
        state.additionalComment = {
          active: action.payload,
          comment: "",
        };
        return;
      }

      state.additionalComment.active = action.payload;
      if (!action.payload) {
        state.additionalComment.comment = "";
      }
    },
    setGift: (state, action: PayloadAction<IGift | undefined>) => {
      state.gift = action.payload;
    },
    setGiftActive: (state, action: PayloadAction<boolean>) => {
      if (state.gift?.active === undefined) {
        state.gift = {
          active: action.payload,
          from: "",
          to: "",
          comment: "",
        };
        return;
      }
      state.gift.active = action.payload;
      if (!action.payload) {
        state.gift.from = "";
        state.gift.to = "";
        state.gift.comment = "";
      }
    },
    setAddress: (state, action: PayloadAction<AddressAttr | undefined>) => {
      state.address = action.payload;
      state.service = null;
      state.pickupStore = null;
    },
    setPickUpStore: (state, action: PayloadAction<PickUpStore | null>) => {
      state.pickupStore = action.payload;
      state.address = undefined;
      state.service = null;
    },
    setService: (state, action: PayloadAction<IService | null>) => {
      state.service = action.payload;
    },
    setPayment: (state, action: PayloadAction<PaymentMethods | null>) => {
      state.payment = action.payload;
    },
    setCoupon: (state, action: PayloadAction<CouponCheckout | null>) => {
      state.coupon = action.payload ?? null;
    },
    setUseCredit: (state, action: PayloadAction<boolean>) => {
      state.useFitpoint = action.payload;
      state.useFriendPoints = action.payload;
    },
    setSendWhatsApp: (state, action: PayloadAction<boolean>) => {
      state.sendWhatsApp = action.payload;
    },
    setOrderDeliveryTo: (state, action: PayloadAction<{ id: EDeliveryOptions; name?: string } | null>) => {
      state.orderDeliveryTo = action.payload;
    },
    setErrorMessageAPI: (state, action: PayloadAction<string | null>) => {
      state.errorMessageAPI = action.payload ?? null;
    },
    setExtraLoading: (state, action: PayloadAction<boolean>) => {
      state.extraLoading = action.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [storeGlobalActions.emptyCheckout.type]: () => initialState,
  },
});

export const checkoutAction = checkoutSlice.actions;
