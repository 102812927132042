import { forwardRef, type ReactNode } from "react";
import type { RuleSet } from "styled-components";
import type { TwStyle } from "twin.macro";
import TextField, { type ITextFieldProps } from "@atoms/TextField";
import FormControl from "../FormControl";

export interface IInputProps extends ITextFieldProps {
  label: string; // If you do not need a label, please use Atoms/TextField.
  textHelp?: ReactNode;
  classes?: {
    root?: RuleSet<object> | TwStyle;
    input?: RuleSet<object> | TwStyle;
  };
  withIconSuccessError?: boolean;
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ label, textHelp, fullWidth = true, id, classes, withIconSuccessError, ...props }, ref) => {
    return (
      <FormControl
        label={label}
        textHelp={textHelp}
        disabled={props.disabled}
        success={props.success}
        errors={props.errors}
        fullWidth={fullWidth}
        size={props.size}
        id={id}
      >
        <TextField
          {...props}
          fullWidth={fullWidth}
          ref={ref}
          classes={classes}
          withIconSuccessError={withIconSuccessError}
        />
      </FormControl>
    );
  }
);

export default Input;
