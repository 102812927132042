import { type ReactNode } from "react";
import type { TSize } from "@/types/common";
import { StyledWrapperInput, StyledText, StyledLabel } from "./styles";

export interface IFormControlProps {
  children: ReactNode;
  label?: string;
  textHelp?: ReactNode;
  disabled?: boolean;
  success?: boolean;
  errors?: boolean;
  fullWidth?: boolean;
  size?: TSize;
  id?: string;
}

const FormControl = ({ ...props }) => {
  return (
    <StyledWrapperInput $fullWidth={props?.fullWidth}>
      {props?.label && (
        <StyledLabel htmlFor={props?.id} size={props?.size} $disabled={props?.disabled}>
          {props.label ?? ""}
        </StyledLabel>
      )}

      {props?.children}

      {props?.textHelp && (
        <StyledText
          sizeVariant="sm"
          $disabled={props.disabled}
          $success={props.success}
          $errors={props.errors}
        >
          {props.textHelp}
        </StyledText>
      )}
    </StyledWrapperInput>
  );
};

export default FormControl;
