"use client";

import { createContext, type PropsWithChildren, type ReactNode, useContext, useState } from "react";
import Modal, { type ModalProps } from "@atoms/Modal";
import ModalAlert, { type ModalAlertProps } from "@molecules/ModalAlert";

type LocalModalProps = Omit<ModalProps, "children" | "handleClose" | "isOpen">;

interface ModalContextType {
  showModal: (
    content: React.ReactNode,
    options?: {
      canReplaceModal?: boolean;
      modalProps?: LocalModalProps;
    }
  ) => void;
  showAlertModal: (options: ModalAlertProps) => void;
  hideModal: () => void;
  isVisible: boolean;
  content: React.ReactNode | null;
}

const defaultState: ModalContextType = {
  showModal: () => {
    throw new Error("showModal not implemented");
  },
  showAlertModal: () => {
    throw new Error("showAlertModal not implemented");
  },
  hideModal: () => {
    throw new Error("hideModal not implemented");
  },
  isVisible: false,
  content: null,
};

export const ModalContext = createContext<ModalContextType>(defaultState);

const ModalContextProvider = ({ children }: PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState<ReactNode | null>(null);
  const [modalProps, setModalProps] = useState<LocalModalProps | null>(null);

  const showModal: ModalContextType["showModal"] = (modalContent: ReactNode, options) => {
    const { canReplaceModal = true, modalProps: modalPropsOpt } = options ?? {};

    if (!canReplaceModal && isVisible) {
      return;
    }

    if (modalPropsOpt) {
      setModalProps(modalPropsOpt);
    }
    setContent(modalContent);
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
    setContent(null);
  };

  const showAlertModal: ModalContextType["showAlertModal"] = (options) => {
    showModal(<ModalAlert {...options} />, {
      canReplaceModal: true,
      modalProps: {
        closeOutSise: true,
      },
    });
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal, isVisible, content, showAlertModal }}>
      {children}

      {isVisible && (
        <Modal {...modalProps} isOpen={isVisible} handleClose={hideModal}>
          {content}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalContext must be used within a ModalContextProvider");
  }

  return context;
};

export default ModalContextProvider;
