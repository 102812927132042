export const sortValueObjectByBoolean = (array: any[], field: string) =>
  [...array]?.sort((x, y) => (x?.[field] === y?.[field] ? 0 : x?.[field] ? -1 : 1));

export const formatArrayWithCommas = (arr: Record<any, any>) => {
  return arr.reduce((acc, link, index, array) => {
    if (index === 0) {
      return [...acc, link];
    } else if (index === array.length - 1) {
      return [...acc, " y ", link];
    } else {
      return [...acc, ", ", link];
    }
  }, []);
};
