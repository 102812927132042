import { useCallback, useEffect, useMemo, useState, type MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import useCart from "@/hooks/useCartV2";
import { existProductCart } from "@/utils/cartV2";
import { calculatePercentageDiscount } from "@/utils/money";

interface IUseProductCardGallery {
  product?: Cart;
}

// TODO: RENAME PRODUCT TO ITEM
export default function useProductCardGallery({ product }: IUseProductCardGallery) {
  const navigate = useNavigate();
  const { items, handleAddCart, handleRemove, handleDecrement, handleChangeInput, ...props } = useCart();
  const [showInput, setShowInput] = useState(false);
  const [userIsTyping, setUserIsTyping] = useState(false);
  const productFinalPricePerStepQty = (product?.price.finalPrice ?? 0) * (product?.stepQty ?? 1);
  const productOriginalPricePerStepQty = (product?.price.originalPrice ?? 0) * (product?.stepQty ?? 1);

  const cartItem = product ? existProductCart(items, +product?.product?.id) : null;

  const productQuantity = useMemo(() => {
    return cartItem?.quantity ?? 0;
  }, [cartItem?.quantity]);

  const getGramans = useCallback(
    (productQty: number) => {
      if ((cartItem?.stepQty ?? 1) >= 2 && cartItem?.details.measureTypeDisplayShort) {
        return cartItem?.details.measureTypeDisplayShort;
      }
      return productQty === 1 ? "unidad" : "unidades";
    },
    [cartItem?.stepQty, cartItem?.details.measureTypeDisplayShort]
  );

  const isProductAvailable = useMemo(() => {
    return product?.details.available;
  }, [product?.details.available]);

  const percentageDiscount = useMemo(
    () =>
      calculatePercentageDiscount({
        priceDiscount: product?.price.finalPrice ?? 0,
        priceOriginal: product?.price.originalPrice ?? 0,
      }),
    [product?.price.originalPrice, product?.price.finalPrice]
  );

  const handleAddToCart = () => {
    if (!isProductAvailable || !product) {
      return;
    }
    setUserIsTyping(false);
    handleAddCart(product);
  };

  const handledToCleanCart = () => {
    if (!product) {
      return;
    }
    handleRemove(+product.product.id, () => {
      setShowInput(false);
      setUserIsTyping(false);
    });
  };

  const handleToDecrement = () => {
    if (!product) {
      return;
    }
    handleDecrement(+product.product.id, () => {
      setShowInput(false);
      setUserIsTyping(false);
    });
  };

  const handleToChangeInput = (quantity: number) => {
    if (!product) {
      return;
    }
    setUserIsTyping(true);
    if (!quantity) {
      handleChangeInput(+product.product.id, "");
      setShowInput(true);
      return;
    }
    handleChangeInput(+product.product.id, quantity.toString());
  };

  const handleGoToBrand = (e: MouseEvent<HTMLHeadingElement>) => {
    e.preventDefault();

    // Go to brand page
    navigate(`/b/${product?.product.brand.slug}`);
  };

  useEffect(() => {
    if (!userIsTyping) {
      setShowInput(productQuantity >= 1);
    }
  }, [productQuantity, userIsTyping]);

  useEffect(() => {
    const $counterElement = document.getElementById("counter-input");

    const handleMouseDown = (e: MouseEvent) => {
      if ($counterElement && !$counterElement.contains(e.target as Node)) {
        if (productQuantity === 0) {
          handledToCleanCart();
        }
      }
    };

    if (showInput) {
      document.addEventListener("mousedown", handleMouseDown);
      return () => {
        document.removeEventListener("mousedown", handleMouseDown);
      };
    }
  }, [showInput, productQuantity, product?.stepQty]);

  return {
    // states:
    showInput,
    productQuantity,
    isProductAvailable,
    percentageDiscount,
    productFinalPricePerStepQty,
    productOriginalPricePerStepQty,

    // functions:
    getGramans,
    handleAddToCart,
    handledToCleanCart,
    handleToDecrement,
    handleToChangeInput,
    handleGoToBrand,

    // rest:
    ...props,
  };
}
