import React from "react";
import {
  StyledIconError,
  StyledIconInfo,
  StyledIconWarning,
  StyledNotificationItem,
  StyledNotificationWrapper,
  StyledText,
} from "./styles";

export interface NotificationProps {
  messages: string | React.ReactNode[];
  type: "info" | "warning" | "error";
  hFull?: boolean;
  aditionalMessage?: React.ReactNode;
}

export interface renderMessagesProps {
  messages: NotificationProps["messages"];
  type: NotificationProps["type"];
  aditionalMessage?: React.ReactNode;
}
function renderIcon(type: NotificationProps["type"]) {
  switch (type) {
    case "info":
      return <StyledIconInfo />;
    case "warning":
      return <StyledIconWarning />;
    case "error":
      return <StyledIconError />;
    default:
      return null;
  }
}

function renderMessages({ messages, type, aditionalMessage }: renderMessagesProps) {
  if (messages instanceof Array) {
    return (
      <StyledNotificationItem type={type}>
        {renderIcon(type)}
        <div>
          <StyledText sizeVariant="sm" type={type}>
            {messages[0]}
          </StyledText>
          {messages.slice(1).length > 0 && (
            <div>
              {messages.slice(1).map((message, index) => (
                <StyledNotificationItem key={index} type={type}>
                  <span className="mr-2">-</span>
                  <StyledText sizeVariant="sm" type={type}>
                    {message}
                  </StyledText>
                </StyledNotificationItem>
              ))}

              {aditionalMessage && (
                <StyledText sizeVariant="sm" type={type}>
                  {aditionalMessage}
                </StyledText>
              )}
            </div>
          )}
        </div>
      </StyledNotificationItem>
    );
  }
  if (typeof messages === "string") {
    return (
      <StyledNotificationItem type={type}>
        {renderIcon(type)}
        <div>
          <StyledText sizeVariant="sm" type={type}>
            {messages}
          </StyledText>
          {aditionalMessage}
        </div>
      </StyledNotificationItem>
    );
  }
}
const Notification = ({ messages, type, hFull, aditionalMessage }: NotificationProps) => {
  return (
    <StyledNotificationWrapper type={type}>
      {renderMessages({ messages, type, aditionalMessage })}
    </StyledNotificationWrapper>
  );
};
export default Notification;
