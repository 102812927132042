import tw, { styled } from "twin.macro";
import IconMastercard from "@iconsV2/Cart/mastercard.svg?react";
import IconVisa from "@iconsV2/Cart/visa.svg?react";
import Image from "../../Atoms/Image";

export const StyledIconMastercard = styled(IconMastercard)`
  ${tw`w-[1.25rem] h-[1.25rem] large:(w-[1.5rem] h-[1.5rem])`}
`;

export const StyledIconVisa = styled(IconVisa)`
  ${tw`w-[1.25rem] h-[1.25rem] large:(w-[1.5rem] h-[1.5rem])`}
`;

export const StyledImage = styled(Image)`
  ${tw`h-[1.25rem] large:(h-[1.5rem])`}
`;
