import dayjs, { type ConfigType, type OptionType, type Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import dayjsTimezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import es from "dayjs/locale/es";
import { COUNTRYCODE } from "@/recourses/constants";
import { countries } from "./CountriesConfig";

const country = countries.find((c) => c.code === COUNTRYCODE);

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);
dayjs.extend(relativeTime);
dayjs.tz.setDefault(country?.time_zone);
dayjs.locale(es);

interface TimezonedDayjs {
  format?: OptionType;
  locale?: string;
  strict?: boolean;
  canTz?: boolean;
}

function timezonedDayjs(date?: ConfigType, options?: TimezonedDayjs): Dayjs {
  const { format, locale, strict, canTz = true } = options ?? {};
  if (!canTz) {
    return dayjs(date, format, locale, strict);
  }

  if (typeof strict === "string") {
    return dayjs(date, format, strict, strict).tz();
  }
  return dayjs(date, format, undefined, strict).tz();
}

timezonedDayjs.duration = dayjs.duration;

export default timezonedDayjs;
