import { type OpUnitType } from "dayjs";
import { capitalize } from "lodash";
import dayjs from "@utils/dayjs";

export function formatDate(date: Date | string, formatStr: string = "DD/MM/YY") {
  return dayjs(date).format(formatStr);
}

export const hasPassedMoreThanDate = (date?: string, type: OpUnitType = "day", quantity: number = 1) => {
  if (!date) return;
  const now = dayjs();
  const dateToCompare = dayjs(date);

  // Calculate the difference in days
  const daysDifference = now.diff(dateToCompare, type);

  // Return true if the difference is greater than days props
  return daysDifference >= quantity;
};

export const getDayDate = (date: Date | string, formatStr: string = "dddd[,] D [de] MMMM") => {
  const today = dayjs();
  const inputDate = dayjs(date);

  if (inputDate.isSame(today, "day")) {
    return "Hoy";
  }
  if (inputDate.isSame(today.add(1, "day"), "day")) {
    return "Mañana";
  }
  return capitalize(formatDate(inputDate.toDate(), formatStr));
};

export const getDayServiceFormatted = (
  date: Date | string,
  hour: string,
  formatStr: string = "YYYY-MM-DDTHH:mm:ssZ"
) => {
  if (!hour) {
    hour = "00:00:00";
  }
  const formatDate = dayjs(date).format("YYYY-MM-DD");

  // temporally date (td)
  // this is a temporal date to get the hour and minute
  const td = dayjs(`${formatDate} ${hour}`, {
    canTz: false,
  });

  return dayjs(`${formatDate}T${hour}`)
    .hour(td.hour())
    .minute(td.minute())
    .second(td.second())
    .format(formatStr);
};

export function convertSeconds(totalSeconds: number): string {
  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;

  const days = Math.floor(totalSeconds / secondsInDay);
  totalSeconds %= secondsInDay;

  const hours = Math.floor(totalSeconds / secondsInHour);
  totalSeconds %= secondsInHour;

  const minutes = Math.floor(totalSeconds / secondsInMinute);
  const seconds = totalSeconds % secondsInMinute;

  let result = "";
  if (days > 0) {
    result += `${days} d `;
  }
  if (hours > 0) {
    result += `${hours} h `;
  }
  if (minutes > 0) {
    result += `${minutes} m `;
  }
  if (seconds > 0 || result === "") {
    result += `${seconds} s`;
  }

  return result.trim();
}

export const hasExpired = (updatedAt?: number | string, minutetoExpired = 30) => {
  if (!updatedAt) {
    return true;
  }

  return dayjs().isAfter(dayjs(updatedAt).add(minutetoExpired, "minutes"));
};

export const formatEventDate = (date: string) => {
  return formatDate(date, "D [de] MMMM, YYYY - h a");
};
