import { createRoot } from "react-dom/client";

import { App } from "./App";
import { initializeSentry } from "./recourses/sentry";
import { POSTHOG_API_KEY } from "./recourses/constants";

/* Init Sentry frist */
initializeSentry();

document.addEventListener("DOMContentLoaded", async () => {
  await import("posthog-js").then((PostHogModule) => {
    const posthog = PostHogModule.default;
    if (POSTHOG_API_KEY) {
      posthog.init(POSTHOG_API_KEY, {
        api_host: "https://app.posthog.com",
        autocapture: false,
        disable_session_recording: false,
        capture_pageview: false, // No capturar métricas de performance
        disable_persistence: true, // No persistir datos en localStorage
      });
    }
  });
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
